import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import axiosInstance from '../config/axiosConfig';
import { useParams, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslate } from '../config/translateContext';
import { Link } from 'react-router-dom';


const UpdateProfileView = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState('');
  const [userData, setUserData] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [lands, setLands] = useState([]);
  const [selectedLand, setSelectedLand] = useState(null);
  const [cities, setCities] = useState([]);
  const [myCity, setMyCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [categories, setCategories] = useState(null);
  const [currentImgField, setCurrentImgField] = useState('');
  const [loading, setLoading] = useState(true);
  const [isDeutsch, setIsDeutsch] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [mySubscription, setMySubscription] = useState(null);
  const [showEdit, setShowEdit] = useState(true);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [countries, setCountries] = useState([]);

  const { isTranslated, setIsTranslated } = useTranslate();

const [profileStatus, setProfileStatus] = useState({
  isCountry: undefined,
  isSprache: undefined,
  isBirth: undefined,
});


  const [modalData, setModalData] = useState({
  country: '',
  birth: '',
  sprache: ''
});

const [isModalOpen, setIsModalOpen] = useState(false);

const fetchCountries = async () => {
  try {
    const response = await axiosInstance.get(`/profile/update/conf/${slug}/`);
    return response.data;  // Zwrócenie danych z API (lista krajów)
  } catch (error) {
    console.error('Error fetching countries:', error);
    return null;
  }
};
 useEffect(() => {
    const getCountries = async () => {
      const fetchedCountries = await fetchCountries(slug);
      if (fetchedCountries) {
        setCountries(fetchedCountries);  // Tutaj wywołujesz setCountries
      }
    };

    getCountries();  // Wywołanie funkcji po załadowaniu komponentu
  }, [slug]);


useEffect(() => {
  if (
    profileStatus.isCountry !== undefined &&
    profileStatus.isSprache !== undefined &&
    profileStatus.isBirth !== undefined
  ) {
    if (!profileStatus.isCountry || !profileStatus.isSprache || !profileStatus.isBirth) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }
}, [profileStatus]);



const handleModalInputChange = (e) => {
  setModalData({
    ...modalData,
    [e.target.name]: e.target.value
  });
};

const handleModalSubmit = async (e) => {
  e.preventDefault();
  try {
    const token = localStorage.getItem('access_token');
    await axiosInstance.put(`/profile/update/conf/${slug}/`, modalData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    setIsModalOpen(false); // Zamknij modal po sukcesie
    fetchProfileData();  // Zaktualizuj dane profilu
  } catch (error) {
    console.error('Error updating profile data:', error);
  }
};




   const fetchProfileStatus = async () => {
    try {
      const response = await axiosInstance.get(`/profile/update/${slug}/`);
      setProfileStatus(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching profile status:', error);
    }
  };

  useEffect(() => {
    fetchProfileStatus();
  }, [slug]);
  const fileInputRefs = {
    profilimg: useRef(null),
    img1: useRef(null),
    img2: useRef(null),
    img3: useRef(null),
    img4: useRef(null),
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchProfileData = async () => {
    setLoading(true);
    const token = localStorage.getItem('access_token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await axiosInstance.get(`/profile/${slug}/`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const { profile, landy, is_deutsch, all_subscriptions, my_subscription, cities, my_city, my_land } = response.data;

      setUserData(profile.user);
      setProfileData(profile);
      setLands(landy);
      setCities(cities);
      setMyCity(my_city);
      setIsDeutsch(is_deutsch);
      setSubscriptions(all_subscriptions);
      setMySubscription(my_subscription);
      setFormData({
        sprache: profile.sprache || '',
        name: profile.user.first_name || '',
        surname: profile.user.last_name || '',
        description: profile.description || '',
        company: profile.company || '',
        land: profile.land?.name || '',
        city: my_city?.name || '',
        kod : profile.kod || '',
        miasto : profile.miasto || '',
        category: profile.category || '',
        website: profile.website || '',
        tel: profile.tel || '',
      });
      setSelectedLand(landy.find(land => land.name === profile.land?.name) || null);
    } catch (error) {
      const errorMessage = error.response ? error.response.statusText : error.message;
      console.error('Error fetching profile data:', errorMessage);
      setErrors(`Error fetching profile data: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    setLoading(true);
    fetchProfileData();
    setLoading(false);
  }, [slug, navigate]);

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem('access_token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      console.log('Submitting data:', formData);
      await axiosInstance.put(`/profile/update/${slug}/`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      await fetchProfileData();
      handleClose();
    } catch (error) {
      const errorMessage = error.response ? error.response.statusText : error.message;
      console.error('Error updating profile:', errorMessage);
      setErrors(`Error updating profile: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

const handleImageClick = (imgField) => {
  // Ustawia aktualnie edytowane pole
  setCurrentImgField(imgField);

  // Wywołuje kliknięcie na odpowiednim input pliku
  fileInputRefs[imgField].current.click();

  // Nasłuchiwanie na zmianę pliku (asynchroniczne wysyłanie pliku na serwer)
  fileInputRefs[imgField].current.onchange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const imgFormData = new FormData();
    imgFormData.append(imgField, file);

    const token = localStorage.getItem('access_token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      // Wysłanie PUT do endpointu
      await axiosInstance.put(`/profile/update/${slug}/`, imgFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });

      // Aktualizacja danych profilu po udanym przesłaniu
      fetchProfileData();
    } catch (error) {
      console.error('Error updating profile image:', error);
      setErrors(`Error updating profile image: ${error.response ? error.response.statusText : error.message}`);
    }
  };
};


  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const imgFormData = new FormData();
    imgFormData.append(currentImgField, file);

    const token = localStorage.getItem('access_token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/profile/update/${slug}/`, imgFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      fetchProfileData();
    } catch (error) {
      console.error('Error updating profile image:', error);
      setErrors(`Error updating profile image: ${error.response ? error.response.statusText : error.message}`);
    }
  };

  useEffect(() => {
    if (showEdit) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [showEdit]);



  const handleSelectLand = async (eventKey) => {
    const selected = lands.find(land => land.id.toString() === eventKey);
    setSelectedLand(selected);
    setFormData(prevFormData => ({
      ...prevFormData,
      land: selected.name,
      city: ''  // reset selected city when land changes
    }));
  
    const token = localStorage.getItem('access_token');
    if (!token) {
      navigate('/login');
      return;
    }
  
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/landy`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          land_id: selected.id
        }
      });
      const cities = response.data.cities;
      setCities(cities);
    } catch (error) {
      console.error('Error fetching cities:', error);
      setErrors(`Error fetching cities: ${error.response ? error.response.statusText : error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  
  const handleSelectCity = async (eventKey) => {
    const selected = cities.find(city => city.id.toString() === eventKey);
    setSelectedCity(selected);
    setFormData(prevFormData => ({
      ...prevFormData,
      city: selected.name,
      category: ''  // reset selected category when city changes
    }));
  
    const token = localStorage.getItem('access_token');
    if (!token) {
      navigate('/login');
      return;
    }
  
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/landy`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          city_id: selected.id
        }
      });
      const categories = response.data.categories;
      setCategories(categories);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setErrors(`Error fetching categories: ${error.response ? error.response.statusText : error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  const handleCategorySelect = async (eventKey) => {
    const selected = categories.find(cat => cat.name === eventKey);
    setFormData(prevFormData => ({
      ...prevFormData,
      category: selected.name,
      job: ''  // reset selected job when category changes
    }));
  
    const token = localStorage.getItem('access_token');
    if (!token) {
      navigate('/login');
      return;
    }
  
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/landy`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          category_id: selected.id
        }
      });
      const jobs = response.data.jobs;
      setJobs(jobs);
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setErrors(`Error fetching jobs: ${error.response ? error.response.statusText : error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  
  const redirectToWebsite = (url) => {
    const absoluteUrl = ensureProtocol(url);
    window.location.href = absoluteUrl;
  };

  const ensureProtocol = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return 'http://' + url;
    }
    return url;
  };

  if (!profileData) {
    return (
      <div className='w-100 d-flex justify-content-center h-100 ontop position-fixed t-0 cloude'>
        <img src='/img/logo.png' className='rotating-object align-self-center loader' alt="Loader" />
      </div>
    );
  }
  if (loading) {
    return (
      <div className='w-100 d-flex justify-content-center h-100 ontop position-fixed t-0 cloude'>
        <img src='/img/logo.png' className='rotating-object align-self-center loader' alt="Loader" />
      </div>
    );
  }

  return (
    <>

<Modal
  show={isModalOpen}
  backdrop="static"
  keyboard={false}
  centered
>
  <Modal.Header>
    <Modal.Title>{isTranslated ? 'Bitte füllen Sie die Felder aus' : 'Proszę wypełnić pola'}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <p className='text-danger'>
  {isTranslated
    ? 'Um unser Portal nutzen zu können, benötigen wir diese drei Informationen. Denken Sie daran, dass Minderjährige Obrotni.de nicht nutzen dürfen.'
    : 'Aby móc korzystać z naszego portalu potrzebujemy tych trzech informacji. Pamiętaj osoby nieletnie nie mogą korzystać z Obrotni.de'}
</p>

    <form onSubmit={handleModalSubmit}>
    <div className="mb-3">
  <label htmlFor="country" className="form-label">{isTranslated ? 'Land' : 'Kraj'}:</label>
  <select
    className="form-select"
    id="country"
    name="country"
    value={modalData.country}
    onChange={handleModalInputChange}
    required
  >
    <option value="">{isTranslated ? 'Wählen Sie ein Land' : 'Wybierz kraj'}</option>
    {countries.map((country) => (
      <option key={country.id} value={country.name}>
        {isTranslated ? country.name_de : country.name}
      </option>
    ))}
  </select>
</div>

      <div className="mb-3">
        <label htmlFor="birth" className="form-label">{isTranslated ? 'Geburtsdatum' : 'Data urodzenia'}:</label>
        <input
          type="date"
          className="form-control"
          id="birth"
          name="birth"
          value={modalData.birth}
          onChange={handleModalInputChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="sprache" className="form-label">{isTranslated ? 'Sprache' : 'Język'}:</label>
        <select
          className="form-select"
          id="sprache"
          name="sprache"
          value={modalData.sprache}
          onChange={handleModalInputChange}
          required
        >
          <option value="Polski">{isTranslated ? 'Polnisch' : 'Polski'}</option>
          <option value="Deutsch">{isTranslated ? 'Deutsch' : 'Niemiecki'}</option>
        </select>
      </div>
      <Button variant="primary" type="submit">
        {isTranslated ? 'Speichern' : 'Zapisz'}
      </Button>
    </form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" disabled>
      {isTranslated ? 'Schließen' : 'Zamknij'}
    </Button>
  </Modal.Footer>
</Modal>







      <div className={`container my-5 vh10 edit ${showEdit ? 'd-block' : 'd-none'}`}>
        <h2>{isTranslated ? 'Profil bearbeiten' : 'Edytuj Profil'}</h2>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item" aria-current="page">
              <a href="#edit" onClick={() => { setShowEdit(true); setShowSubscribe(false); }}>
                {isTranslated ? 'Profil bearbeiten' : 'Edytuj profil'}
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#" onClick={() => { setShowEdit(false); setShowSubscribe(true); }}>
                {isTranslated ? 'Abonnements' : 'Subskrybcje'}
              </a>
            </li>
          </ol>
        </nav>
        {profileData && (
          <div className='profile-name'>
            <h2>{profileData.name} {profileData.surname}</h2>
          </div>
        )}
        <div className='d-flex justify-content-center w-100 mx-auto'>
          {profileData && (
            <div className='profile-img' onClick={() => handleImageClick('profilimg')}>
              <img    src={profileData && profileData.profilimg ? profileData.profilimg : "/img/input.png"}
               alt="Profile" className='profile-img' />
              <div className='profile-overlay'>
                <h2 className='text-white'>{isTranslated ? 'Ändern' : 'Zmień'}</h2>
              </div>
              <input
                type="file"
                name="profilimg"
                ref={fileInputRefs.profilimg}
                style={{ display: 'none' }}
                onChange={handleImageChange}
              />
            </div>
          )}
        </div>
      </div>
      <div className={`vh10 container profile-update ${showEdit ? 'd-block' : 'd-none'}`}>
        {profileData && (
        <>
          <div className='row px-5 pt-5 my-5'>
            <div className='col-md-3 col-lg-3 col-12 col-sm-12 cloude pt-5 d-flex'>
              <div className='d-block'>
                <button className='btn btn-link d-flex justify-content-between' onClick={handleShow}>   <i className="bi bi-pencil-square"></i></button>
                <ul className='list-unstyled'>
                  <li><h2>{profileData.job?.name}</h2></li>
                  <li>{profileData.land?.name}</li>
                  <li>{isTranslated ? `Bezirk: ${profileData.city?.name}` : `Powiat:  ${profileData.city?.name}`}</li>
                  <li>{isTranslated ? `Stadt: ${profileData.miasto}` : `Miasto: ${profileData.kod} ${profileData.miasto}`}</li>
                  <li><i class="bi bi-at"></i>{profileData.user.email}</li>
                  <li>
                   
                      <h5 className='my-5'> {profileData.website? profileData.website : ''} </h5>
                   
                  </li>
                  <li>

                      <h5 className='indigo mb-2'>{profileData.tel}</h5>

                  </li>
                </ul>
              </div>
            </div>

            <div className='col-md-8 col-lg-8 col-12 col-sm-12 py-5'>
              <div key={profileData.id} dangerouslySetInnerHTML={{ __html: profileData.description }} />
            </div>
          </div>
        </>
        )}
        <Modal
          show={show}
          onHide={handleClose}
          animation={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className='mx-auto p-1'
        >
          <Modal.Header closeButton>
            <Modal.Title>{isTranslated ? 'Profil bearbeiten' : 'Edytuj Profil'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit} className='w-100'>
              <div className="mb-3">
 <label htmlFor="sprache" className="form-label">
  {isTranslated ? 'Język:' : 'Sprache:'}
</label>
<select
  className="form-select"
  id="sprache"
  name="sprache"
  value={formData.sprache}
  onChange={handleChange}
>
  <option value="Polski">
    {isTranslated ? 'Polski' : 'Polnisch'}
  </option>
  <option value="Deutsch">
    {isTranslated ? 'Niemiecki' : 'Deutsch'}
  </option>
</select>

              </div>

<Dropdown onSelect={handleSelectLand} className='my-2'>
  <Dropdown.Toggle variant="success" id="dropdown-basic">
    {selectedLand ? selectedLand.name : (isTranslated ? "Wählen Sie ein Land" : "Wybierz land")}
  </Dropdown.Toggle>
  <Dropdown.Menu>
    {lands && lands.map((land) => (
      <Dropdown.Item key={land.id} eventKey={land.id.toString()}>
        {isTranslated ? land.name_de : land.name}
      </Dropdown.Item>
    ))}
  </Dropdown.Menu>
</Dropdown>

              
              <Dropdown onSelect={handleSelectCity} className='my-2'>
<Dropdown.Toggle variant="success" id="dropdown-basic">
  {formData.city ? formData.city : (isTranslated ? "Wählen Sie eine Stadt" : "Wybierz miasto")}
</Dropdown.Toggle>
<Dropdown.Menu>
  {cities && cities.map((city) => (
    <Dropdown.Item key={city.id} eventKey={city.id.toString()}>
      {isTranslated ? city.name_de : city.name}
    </Dropdown.Item>
  ))}
</Dropdown.Menu>

              </Dropdown>
              
             <Dropdown onSelect={handleCategorySelect} className='my-2'>
  <Dropdown.Toggle variant="success" id="dropdown-basic">
    {formData.category ? formData.category : (isTranslated ? "Wählen Sie eine Kategorie" : "Wybierz kategorię")}
  </Dropdown.Toggle>
  <Dropdown.Menu>
    {categories && categories.map((cat) => (
      <Dropdown.Item key={cat.id} eventKey={cat.name}>
        {isTranslated ? cat.name_de : cat.name}
      </Dropdown.Item>
    ))}
  </Dropdown.Menu>
</Dropdown>

              
            <Dropdown onSelect={(eventKey) => setFormData(prevFormData => ({ ...prevFormData, job: eventKey }))} className='my-2'>
  <Dropdown.Toggle variant="success" id="dropdown-basic">
    {formData.job ? formData.job : (isTranslated ? "Wählen Sie eine Arbeit" : "Wybierz pracę")}
  </Dropdown.Toggle>
  <Dropdown.Menu>
    {jobs && jobs.map((job) => (
      <Dropdown.Item key={job.id} eventKey={job.name}>
        {isTranslated ? job.name_de : job.name}
      </Dropdown.Item>
    ))}
  </Dropdown.Menu>
</Dropdown>

              
<div>
  <label className="form-label" htmlFor="name">
    {isTranslated ? "Vorname:" : "Imię:"}
  </label>
  <input
    className="form-control"
    type="text"
    id="name"
    name="name"
    value={formData.name}
    onChange={handleChange}
    placeholder={userData ? userData.first_name : ''}
  />
</div>

<div>
  <label className="form-label" htmlFor="surname">
    {isTranslated ? "Nachname:" : "Nazwisko:"}
  </label>
  <input
    className="form-control"
    type="text"
    id="surname"
    name="surname"
    value={formData.surname}
    onChange={handleChange}
    placeholder={userData ? (isTranslated ? userData.last_name : userData.last_name) : ''}
  />
</div>

<div>
  <label className="form-label" htmlFor="company">
    {isTranslated ? "Unternehmen:" : "Firma:"}
  </label>
  <input
    className="form-control"
    type="text"
    id="company"
    name="company"
    value={formData.company}
    onChange={handleChange}
    placeholder={profileData ? profileData.company : ''}
  />
</div>

        <div>
  <label className="form-label" htmlFor="kod">
    {isTranslated ? "Code:" : "Kod:"}
  </label>
  <input
    className="form-control"
    type="text"
    id="kod"
    name="kod"
    value={formData.kod}
    onChange={handleChange}
    placeholder={profileData ? profileData.kod : ''}
  />
</div>

              <div>
  <label className="form-label" htmlFor="miasto">
    {isTranslated ? "City:" : "Miasto:"}
  </label>
  <input
    className="form-control"
    type="text"
    id="miasto"
    name="miasto"
    value={formData.miasto}
    onChange={handleChange}
    placeholder={profileData ? profileData.miasto : ''}
  />
</div>

            <div>
  <label className="form-label" htmlFor="website">
    {isTranslated ? "Website:" : "Strona internetowa:"}
  </label>
  <input
    className="form-control"
    type="text"
    id="website"
    name="website"
    value={formData.website}
    onChange={handleChange}
    placeholder={profileData ? profileData.website : ''}
  />
</div>

            <div>
  <label className="form-label" htmlFor="tel">
    {isTranslated ? "Phone Number:" : "Numer telefonu:"}
  </label>
  <input
    className="form-control"
    type="text"
    id="tel"
    name="tel"
    value={formData.tel}
    onChange={handleChange}
    placeholder={profileData ? profileData.tel : ''}
  />
</div>

              <div>
                <label className="form-label" htmlFor="description">Description:</label>
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder={profileData ? profileData.description : ''}
                />
              </div>

              <div className='d-flex justify-content-center my-5'>
                <Button variant="primary" type='submit'>
                  {isTranslated ? 'Speichern' : 'Zapisz'}
                </Button>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {isTranslated ? 'Schließen' : 'Zamknij'}
            </Button>
          </Modal.Footer>
        </Modal>


<div className='row'>
<p>
  {isTranslated
    ? "* Drücke das Pluszeichen, um ein Foto zur Galerie hinzuzufügen"
    : "* Naciśnij plus, by dodać zdjęcie do galerii"}
</p>

  <div className='col-md-3 profile-gallery-img d-flex justify-content-center align-items-center p-0 m-auto col-lg-3 col-12 col-sm-12'>
    <img
      src={profileData && profileData.img1 ? profileData.img1 : "/img/input.png"}
      alt={profileData.slug}
      className='profile-gallery'
      style={{ cursor: 'pointer' }}
    />
    <input
      type="file"
      name="img1"
      ref={fileInputRefs.img1}
      style={{ display: 'none' }}
      onChange={handleImageChange}
    />
    <div className='profile-gallery-overlay' onClick={() => handleImageClick('img1')}>
      <h2>{isTranslated ? "Ändern" : "Zmień"}</h2>

    </div>
  </div>

  <div className='col-md-3 profile-gallery-img d-flex justify-content-center align-items-center p-0 m-auto col-lg-3 col-12 col-sm-12'>
    <img
      src={profileData && profileData.img2 ? profileData.img2 : "/img/input.png"}
      alt={profileData.slug}
      className='profile-gallery'
      style={{ cursor: 'pointer' }}
    />
    <input
      type="file"
      name="img2"
      ref={fileInputRefs.img2}
      style={{ display: 'none' }}
      onChange={handleImageChange}
    />
    <div className='profile-gallery-overlay' onClick={() => handleImageClick('img2')}>
      <h2><h2>{isTranslated ? "Ändern" : "Zmień"}</h2>
</h2>
    </div>
  </div>

  <div className='col-md-3 profile-gallery-img d-flex justify-content-center align-items-center p-0 m-auto col-lg-3 col-12 col-sm-12'>
    <img
      src={profileData && profileData.img3 ? profileData.img3 : "/img/input.png"}
      alt={profileData.slug}
      className='profile-gallery'
      style={{ cursor: 'pointer' }}
    />
    <input
      type="file"
      name="img3"
      ref={fileInputRefs.img3}
      style={{ display: 'none' }}
      onChange={handleImageChange}
    />
    <div className='profile-gallery-overlay' onClick={() => handleImageClick('img3')}>
<h2>{isTranslated ? "Ändern" : "Zmień"}</h2>

    </div>
  </div>

  <div className='col-md-3 profile-gallery-img d-flex justify-content-center align-items-center p-0 m-auto col-lg-3 col-12 col-sm-12'>
    <img
      src={profileData && profileData.img4 ? profileData.img4 : "/img/input.png"}
      alt={profileData.slug}
      className='profile-gallery'
      style={{ cursor: 'pointer' }}
    />
    <input
      type="file"
      name="img4"
      ref={fileInputRefs.img4}
      style={{ display: 'none' }}
      onChange={handleImageChange}
    />
    <div className='profile-gallery-overlay' onClick={() => handleImageClick('img4')}>
      <h2>{isTranslated ? "Ändern" : "Zmień"}</h2>

    </div>
  </div>
</div>

      </div>

      <div className={`subscribe container vh10 ${showSubscribe ? 'd-block' : 'd-none'}`}>
        <div className='row my-5'>
          <h2>{isTranslated ? 'Profil bearbeiten' : 'Edytuj Profil'}</h2>
          <nav aria-label="breadcrumb my-5">
            <ol className="breadcrumb">
              <li className="breadcrumb-item" aria-current="page">
                <a href="#edit" onClick={() => { setShowEdit(true); setShowSubscribe(false); }}>
                  {isTranslated ? 'Profil bearbeiten' : 'Edytuj profil'}
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="#" onClick={() => { setShowEdit(false); setShowSubscribe(true); }}>
                  {isTranslated ? 'Abonnements' : 'Subskrybcje'}
                </a>
              </li>
            </ol>
          </nav>
          <h1 className='my-5'>{isTranslated ? 'Handeln!' : 'Działaj!'}</h1>
          {subscriptions && subscriptions.length > 0 ? (
            subscriptions.map((subscription) => (
              <div 
                key={subscription.id} 
                className="col-md-4 col-12 col-sm-12 my-5"
              >
                <div className={`card ${mySubscription && mySubscription.id === subscription.id ? 'glass2' : 'glass'}`}>
                  <div className='card-body'>
                    <h2 className='card-title text-center my-5' dangerouslySetInnerHTML={{ __html: subscription.subscription_type.name }}></h2>
                    <p dangerouslySetInnerHTML={{ __html: subscription.description }} />
                    <div className='d-flex justify-content-center mt-5'>
                      <Link to={`/subscription/${subscription.id}`} className='btn btn-primary'>
                        {isTranslated ? 'abonnieren' : 'subskrybuj'}
                      </Link>  
                    </div>
                  </div>
                </div>
              </div>
            ))

          ) : (
            <div className='w-100 d-flex justify-content-center h-100 ontop position-fixed t-0 cloude'>
              <img src='/img/logo.png' className='rotating-object align-self-center loader' alt="Loader" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UpdateProfileView;
