import axios from 'axios';
import Cookies from 'js-cookie';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 100000,
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    const csrfToken = Cookies.get('csrftoken'); // Get CSRF token from cookies

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Set authorization header
    }
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken; // Set CSRF token header
    }
    return config;
  },
  (error) => {
    console.error('Intercepted request - Error:', error);
    return Promise.reject(error);
  }
);

// Setup Interceptors for Response
export const setupInterceptors = (refreshToken, logout) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If unauthorized (401) and not a retry
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // Mark request as a retry

        const refresh = localStorage.getItem('refresh_token');
        if (refresh) {
          try {
            const response = await axiosInstance.post('/api/token/refresh/', { refresh });
            if (response.status === 200) {
              // Update local storage with new access token
              localStorage.setItem('access_token', response.data.access);
              axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
              originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`;
              return axiosInstance(originalRequest); // Retry original request
            }
          } catch (err) {
            console.error('Error refreshing token:', err);
            logout(); // Handle logout if token refresh fails
            return Promise.reject(err);
          }
        } else {
          logout(); // No refresh token available, logout
        }
      }
      return Promise.reject(error); // Return the original error if not handled
    }
  );
};

export default axiosInstance;
