import React, { useEffect, useState,  useRef  } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { fadeAnimation } from '../animations/gsap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useTranslate } from '../config/translateContext';

import NewsletterForm from '../components/newsletter';

function CategoryPress() {
    const [data, setData] = useState({});
    const { slug } = useParams();
    
    const myFade = useRef(null);
    
  const { isTranslated, toggleTranslate } = useTranslate();
  const [translatedTitles, setTranslatedTitles] = useState({});
  const [translatedIntros, setTranslatedIntros] = useState({});
    useEffect(() => {
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/infoteka/dzialy/${slug}`;
        axios.get(apiUrl)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error("Błąd podczas pobierania danych:", error);
            });
            
    }, [slug]);
    useEffect(() => {
        if (myFade.current) {
            fadeAnimation(myFade.current);
        }
    }, [data]);
    
    const translateTexts = async (text, type, postId = null) => {
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/translate/`;
        try {
          const response = await axios.post(apiUrl, { text: text });
          const translatedText = response.data.translated_text;
      
          if (type === 'title' && postId !== null) {
            setTranslatedTitles(prevTitles => ({
              ...prevTitles,
              [postId]: translatedText // Aktualizuje stan dodając przetłumaczony tytuł dla konkretnego postId
            }));
          } else if (type === 'intro' && postId !== null) {
            setTranslatedIntros(prevIntros => ({
              ...prevIntros,
              [postId]: translatedText // Aktualizuje stan dodając przetłumaczone wprowadzenie dla konkretnego postId
            }));
          }
        } catch (error) {
          console.error("Błąd podczas tłumaczenia tekstu:", error);
        }
      };

    if (!data || !data.presscategory || !data.cykle || !data.artykuly) return <div id="loading" ><img src='/img/logo.png' className='rotating-object img-fluid'/></div>;

    const { presscategory, cykle, artykuly } = data;
    return (
        <div className='container vh10' ref={myFade}>
            <div className='row'></div>
            <div className='row'>
                <h1 className=''>Infoteka</h1>

                <h2 className='blue fs-1 vh10'>{presscategory.name}</h2>
                <Breadcrumb>
                                <Breadcrumb.Item href="/infoteka">{isTranslated ? `Homepage` : `Infoteka`}</Breadcrumb.Item>
                            
                                <Breadcrumb.Item active>{isTranslated ? presscategory.name_de : presscategory.name}</Breadcrumb.Item>
                              </Breadcrumb>
                
            </div>
            {cykle.map(cykl => (
<>

                <div key={cykl.id} className='row my-5'>
                    <h2 className='my-5'>{isTranslated ? `Serie: ${cykl.name_de}` : `Z cyklu: ${cykl.name}`}</h2>
                    {artykuly.filter(artykul => artykul.cykl_id.id === cykl.id).map(filteredArtykul => (
                        



             <div className='col-md-4'>
                      
                        <div className='card'>
                            <img src={`${process.env.REACT_APP_API_BASE_URL}${filteredArtykul.img}`} className="card-img-top" alt={filteredArtykul.title} />
                            <div className='card-body'>
                            <button className='btn btn-link purple' onClick={() => translateTexts(filteredArtykul.title, 'title', filteredArtykul.id)}>übersetzen ins Deutsch</button>
                            <br/>
                            <h5 className='card-title'>{translatedTitles[filteredArtykul.id] || filteredArtykul.title}</h5>

                              
                                <button className='btn btn-link purple' onClick={() => translateTexts(filteredArtykul.intro, 'intro', filteredArtykul.id)}>übersetzen ins Deutsch</button>
                                <p className='card-text'> {translatedIntros[filteredArtykul.id] || filteredArtykul.intro}</p>
                                <Link to={`/infoteka/${filteredArtykul.slug}/`}>{isTranslated ? `lesen` : `przeczytaj`} </Link>
                            </div>
                        </div>
                   
             </div>


                    ))}
                </div>
</>
            ))}
            <div className='row my-5'>
                <NewsletterForm />
            </div>
        </div>
    );
}

export default CategoryPress;
