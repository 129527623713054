import React, { useState } from 'react';
import axiosInstance from '../config/axiosConfig';
import { Form, Button, Alert } from 'react-bootstrap';
import { useTranslate } from '../config/translateContext';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    topic: '',
    message: '',
    acceptPrivacy: false, // State for the privacy policy checkbox
  });
  const { isTranslated } = useTranslate();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if privacy policy is accepted
    if (!formData.acceptPrivacy) {
      setError(isTranslated ? "Bitte akzeptieren Sie die Datenschutzrichtlinie." : "Proszę zaakceptować politykę prywatności.");
      return;
    }

    try {
      const response = await axiosInstance.post('/contact-form/', formData);
      setSuccess(isTranslated ? "Nachricht wurde gesendet!" : "Wiadomość została wysłana!");
      setFormData({
        name: '',
        email: '',
        topic: '',
        message: '',
        acceptPrivacy: false, // Reset the checkbox after submission
      });
    } catch (error) {
      setError(isTranslated ? "Ein Fehler ist beim Senden der Nachricht aufgetreten." : "Wystąpił błąd podczas wysyłania wiadomości.");
    }
  };

  return (
    <div className='container mb-5 '>
      <h1>Kontakt</h1>
      <Form onSubmit={handleSubmit} className='neumorphism'>
        <Form.Group controlId="name" className="mb-3">
          <Form.Label> {isTranslated ? "Vor- und Nachname" : "Imię i nazwisko"}</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="email" className="mb-3">
          <Form.Label>e-mail</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="topic" className="mb-3">
          <Form.Label>  {isTranslated ? "Thema" : "Temat"}</Form.Label>
          <Form.Control
            as="select"
            name="topic"
            value={formData.topic}
            onChange={handleChange}
            required
          >
            <option value="">
              {isTranslated ? "Wählen Sie ein Thema" : "Wybierz temat"}
            </option>
            <option value="technical_issue">
              {isTranslated ? "Technisches Problem" : "Problem techniczny"}
            </option>
            <option value="collaboration">
              {isTranslated ? "Zusammenarbeit" : "Współpraca"}
            </option>
            <option value="new_category">
              {isTranslated ? "Neue Kategorie oder Branche" : "Nowa kategoria lub branża"}
            </option>
            <option value="advertising">
              {isTranslated ? "Werbung" : "Reklama"}
            </option>
            <option value="other">
              {isTranslated ? "Andere" : "Inne"}
            </option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="message" className="mb-3">
          <Form.Label> {isTranslated ? "Nachricht" : "Wiadomość"}</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </Form.Group>

<Form.Group controlId="acceptPrivacy" className="mb-3">
  <Form.Check
    type="checkbox"
    name="acceptPrivacy"
    checked={formData.acceptPrivacy}
    onChange={handleChange}
    label={
      isTranslated ? (
        <>
          Ich habe die Datenschutzrichtlinie gelesen und akzeptiere sie.{" "}
          <a href="/daten" target="_blank" rel="noopener noreferrer">
            Datenschutz
          </a>
        </>
      ) : (
        <>
          Zapoznałam/Zapoznałem się z polityką prywatności i akceptuję ją.{" "}
          <a href="/daten" target="_blank" rel="noopener noreferrer">
            polityka prywatności
          </a>
        </>
      )
    }
    required
  />
</Form.Group>


        <Button variant="primary" type="submit">
          {isTranslated ? "Absenden" : "Wyślij"}
        </Button>

        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
      </Form>
    </div>
  );
};

export default ContactForm;
