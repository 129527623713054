import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLoading } from "../config/loadingContext";
import axios from "axios";
import moment from "moment";

function Daten() {
  const [daten, setDaten] = useState(null);
  const [error, setError] = useState('');
  const { slug } = useParams();
  const { setIsLoading } = useLoading();
  const formattedDate = moment().format("YYYY-MM-DD");
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/daten/${slug}/`;

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        console.log("API Response:", response.data); // Log całej odpowiedzi

        if (response.data && response.data.daten) {
          setDaten(response.data.daten);
        } else {
          setError("Data not found or in an unexpected format.");
          console.error("Unexpected data format:", response.data);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError("Failed to load data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [slug, apiUrl, setIsLoading]);

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!daten) {
    return <p>Loading...</p>;
  }

  console.log("Daten object:", daten); // Log danych daten
  console.log("Daten text:", daten.text); // Log tekstu HTML

  return (
    <div className="container blue my-5">
      <div className="row my-5">
        <h2 className="my-5">DATENSCHUTZERKLÄRUNG</h2>
      </div>
      <div className="my-5 row">
        {daten.text ? (
          <div dangerouslySetInnerHTML={{ __html: daten.text }} className="my-5" />
        ) : (
          <p>Text content not available.</p>
        )}
      </div>
    </div>
  );
}

export default Daten;
