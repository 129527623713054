import React, { useState, useEffect, useLayoutEffect, useRef, useTransition } from 'react';
import { Row, Container, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from 'axios'; 
import { useLoading } from '../config/loadingContext';
import { useTranslate } from '../config/translateContext';
import Loader from './Loader';




export default function Footer() {

    const [document, setDocument] = useState({ impressum: null, daten: null, agd: null });
    const {isTranslated} = useTranslate();
    const { setIsLoading } = useLoading();
    const [error, setError] = useState('');
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
          try {
            // Directly use Axios to make the HTTP request
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/footer-menu`);
            setDocument(response.data); // Set data with the response data
          } catch (error) {
            console.error("Failed to fetch data:", error);
            setError("Failed to load data.");
          } finally {
            setIsLoading(false);
          }
        };
    
        fetchData();
    
      }, []);
    return (
<Container className="p-5 footer">
    <div className='row m-sm-0 p-sm-0'>

        <div className='col-sm-12 col-12 col-md-4  col-lg-4'>
            <p>{isTranslated ? 'Besuchen Sie uns:' : 'Odwiedź nas:'}</p>
            <p>
                <a href="https://ecv-online.com">
                    <img
                        className="footer-logo"
                        src={`${process.env.PUBLIC_URL}/logo/ecv.png`}
                        alt="ECV"
                    />{" "}
                    www.ecv-online.com
                </a>
            </p>
        </div>

        <div className='col-sm-12 col-12 col-md-4  col-lg-4'>
            <p>{isTranslated ? 'Folgen Sie uns!' : 'Polub nas!'}</p>
            <ul className="list-unstyled">
                <li className='my-2'>
                    <a href="https://www.facebook.com/Obrotnide">
                        <img
                            src={`${process.env.PUBLIC_URL}/logo/face.png`}
                            className="footer-logo"
                            alt="Facebook"
                        />{" "}
                        www.facebook.com/Obrotnide
                    </a>
                </li>

                <li className='my-2'>
                    <a href="https://www.instagram.com/obrotni.de/">
                        <img
                            src={`${process.env.PUBLIC_URL}/logo/instagram.png`}
                            className="footer-logo"
                            alt="Instagram"
                        />{" "}
                        www.instagram.com/obrotni.de/
                    </a>
                </li>
            </ul>
        </div>

        <div className='col-sm-12 col-12 col-md-4  col-lg-4'>
            <ul className="list-unstyled list-inline">
                <p>{isTranslated ? 'Wichtige Dokumente' : 'Ważne dokumenty'}</p>
                <li>{document.impressum && <Link to={`/impressum/${document.impressum.slug}`}>Impressum</Link>}</li>
                <li>{document.daten && <Link to={`/daten/${document.daten.slug}`}>{isTranslated ? 'Datenschutz' : 'Polityka prywatności'}</Link>}</li>
                <li>{document.agd && <Link to={`/agb/${document.agd.slug}`}>AGB</Link>}</li>
            </ul>
        </div>

    </div>

    <div className="col-sm-12 col-12 col-md-12 col-lg-12 text-center my-5">
        <span className="text-start blue">
            {isTranslated ? 'Alle Rechte vorbehalten durch e-Cafe Vision 2024' : 'Wszystkie prawa zastrzeżone przez e-Cafe Vision 2024'}
        </span>
    </div>
</Container>

    );
}
