import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslate } from '../config/translateContext';

const Bewerbung = () => {
    const { isTranslated } = useTranslate();

    return (
        <Container className="vh-50 py-5 d-flex justify-content-center align-items-center vh10 neumorphism ">
            <Row>
                <Col className="text-center">
                    <h2 className='text-success'>
                        {isTranslated ? 'Erfolg! Sie haben sich erfolgreich beworben!' : 'Sukces! Udało ci się zaaplikować!'}
                    </h2>
                    <p>
                        {isTranslated ? 'Viel Erfolg bei der Suche nach Ihrem Traumjob. Wir drücken die Daumen!' : 'Życzymy powodzenia w znalezieniu wymarzonej pracy. Trzymamy kciuki!'}
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default Bewerbung;
