import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslate } from '../config/translateContext';
import { useLoading } from '../config/loadingContext';


const PostSuccess = () => {
    const { isTranslated } = useTranslate();
    const {isLoadnig, setIsLoading} = useLoading();

    return (
<Container className="vh-50 py-5 d-flex justify-content-center align-items-center vh10 neumorphism ">
    <Row>
        <Col className="text-center">
            <h2>{isTranslated ? 'Vielen Dank für das Hinzufügen des Artikels.' : 'Dziękujemy za dodanie artykułu.'}</h2>
            <p>{isTranslated ? 'Unser Chefredakteur wird es überprüfen und Sie über die Veröffentlichung informieren.' : 'Nasz redaktor naczelny sprawdzi go i poinformuje o jego publikacji.'}</p>
        </Col>
    </Row>
</Container>

    );
};

export default PostSuccess;
