import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axiosConfig';  // Import axiosInstance do wykonywania żądań

const RegulaminInfoteka = () => {
  const [regulamin, setRegulamin] = useState(null); // Stan do przechowywania regulaminu
  const [loading, setLoading] = useState(true);     // Stan do śledzenia ładowania
  const [error, setError] = useState(null);         // Stan do śledzenia błędów

  useEffect(() => {
    const fetchRegulamin = async () => {
        try {
          const response = await axiosInstance.get(`/infoteka/regulamin/czytaj/`);
          console.log("Otrzymana odpowiedź z API:", response); // Sprawdź pełną odpowiedź
          setRegulamin(response.data.regulamin);
        } catch (err) {
          console.error("Błąd podczas pobierania regulaminu:", err);
          setError("Nie udało się załadować regulaminu");
        } finally {
          setLoading(false);
        }
      };

    fetchRegulamin();  // Wywołanie funkcji przy ładowaniu komponentu
  }, []);  // Efekt zależy od sluga

  // Renderowanie komponentu w zależności od stanu ładowania, błędów lub danych
  if (loading) {
    return <p>Ładowanie...</p>;  // Komunikat podczas ładowania
  }

  if (error) {
    return <p>{error}</p>;  // Komunikat błędu
  }

  return (
    <div className='animatable my-5'>
      {/* Wstawienie treści regulaminu do elementu z użyciem dangerouslySetInnerHTML */}
      <div dangerouslySetInnerHTML={{ __html: regulamin.text }} />
    </div>
  );
};

export default RegulaminInfoteka;
