import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '../config/translateContext';
import { gsap } from 'gsap';

function Register() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [subscriptions, setSubscriptions] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const navigate = useNavigate();
    const { isTranslated } = useTranslate();

    // Stan dla opcji "Pokaż hasło"
    const [showPassword, setShowPassword] = useState(false);

    // Refy dla elementów do animacji
    const subsRef = useRef(null);
    const formRef = useRef(null);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/all-subs/`)
            .then((response) => {
                if (response.data && response.data.subs) {
                    setSubscriptions(response.data.subs);
                }
            })
            .catch((error) => {
                console.error("Error fetching subscriptions:", error);
            });
    }, []);

    useEffect(() => {
        if (subsRef.current && formRef.current) {
            gsap.set(formRef.current, { opacity: 0, display: 'none' });
        }
    }, []);

    // Animacja po wybraniu subskrypcji
    useEffect(() => {
        if (selectedSubscription) {
            if (subsRef.current && formRef.current) {
                // Animacja GSAP
                gsap.to(subsRef.current, {
                    opacity: 0,
                    duration: 0.5,
                    onComplete: () => {
                        gsap.set(subsRef.current, { display: 'none' });
                        gsap.set(formRef.current, { display: 'block' });
                        gsap.to(formRef.current, { opacity: 1, duration: 0.5 });
                    },
                });
            }
        }
    }, [selectedSubscription]);

    const handleRegistration = async (event) => {
        event.preventDefault();
        if (!selectedSubscription) {
            setMessage('Wybierz subskrypcję przed rejestracją.');
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register/`, {
                username,
                email,
                password1,
                password2,
                first_name: firstName,
                last_name: lastName,
                subscription_id: selectedSubscription,
            });
            setMessage('Wiadomość e-mail z linkiem potwierdzającym została wysłana');
            console.log('Zarejestrowano pomyślnie:', response.data);
            // Reset pól formularza
            setUsername('');
            setEmail('');
            setPassword1('');
            setPassword2('');
            setFirstName('');
            setLastName('');
            setSelectedSubscription(null);
            setErrors({});
          } catch (error) {
        if (error.response && error.response.status === 400 && error.response.data) {
            // Update errors state with specific validation messages from response
            setErrors(error.response.data);
        } else {
            console.error('Nie udało się zarejestrować:', error);
        }
        }
    };

const handleChange = (setter, fieldName) => (event) => {
    setter(event.target.value);
    setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: undefined, // Clear errors for this specific field
    }));
};
    return (
        <div className="container my-5">
            <h1>{isTranslated ? 'Registrieren' : 'Rejestracja'}</h1>
            {message && (
                <div className='info-message'>
                    <div className="info-message-text">{message}</div>
                </div>
            )}

            {/* Wybór subskrypcji */}
            <div className="subscription-selection my-5" ref={subsRef}>
                <h2 className='my-5'>{isTranslated ? 'Wählen Sie ein Abonnement' : 'Wybierz subskrypcję'}</h2>
                <div className="row">
                    {subscriptions && subscriptions.length > 0 ? (
                        subscriptions.map((subscription) => (
                            <div className="col-12 col-md-4 col-lg-4 col-sm-12" key={subscription.id}>
                                <div className={`card ${selectedSubscription === subscription.id ? 'glass2' : 'glass'}`}>
                                    <div className="card-body">
                                        <h2 className="card-title text-center my-5">{subscription.subscription_type.name}</h2>
                                     {isTranslated ? (
  <p dangerouslySetInnerHTML={{ __html: subscription.description_de }} />
) : (
  <p dangerouslySetInnerHTML={{ __html: subscription.description }} />
)}

                                        <div className="d-flex justify-content-center mt-5">
                                            <button
                                                className='btn btn-primary'
                                                onClick={() => {
                                                    console.log('Wybrano subskrypcję o ID:', subscription.id);
                                                    setSelectedSubscription(subscription.id);
                                                }}
                                            >
                                                {isTranslated ? 'Wählen' : 'Wybierz'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>{isTranslated ? 'Keine Abonnements verfügbar' : 'Brak dostępnych subskrypcji'}</p>
                    )}
                </div>
            </div>

            {/* Formularz rejestracji */}
            <div className="row rejestr" ref={formRef}>
                <div className='col-12 col-sm-12 col-md-6 offset-md-3 col-lg-6 offset-lg-3'>
                   <form onSubmit={handleRegistration}>
    {/* Błędy ogólne */}
    {errors.non_field_errors && errors.non_field_errors.map((error, index) => (
        <div key={index} className="alert alert-danger">
            {error}
        </div>
    ))}

    {/* Pole Nazwa użytkownika */}
    <div className="mb-3">
        <label htmlFor="username" className="form-label">
            {isTranslated ? 'Benutzername' : 'Nazwa użytkownika'}:
        </label>
        <input
            type="text"
            className={`form-control ${errors.username ? 'is-invalid' : ''}`}
            id="username"
            value={username}
            onChange={(e) => {
                setUsername(e.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, username: undefined }));
            }}
        />
        {errors.username && errors.username.map((error, index) => (
            <div key={index} className="invalid-feedback">
                {error}
            </div>
        ))}
    </div>

    {/* Pole Email */}
    <div className="mb-3">
        <label htmlFor="email" className="form-label">Email:</label>
        <input
            type="email"
            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
            id="email"
            value={email}
            onChange={(e) => {
                setEmail(e.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, email: undefined }));
            }}
        />
        {errors.email && errors.email.map((error, index) => (
            <div key={index} className="invalid-feedback">
                {error}
            </div>
        ))}
    </div>

    {/* Pole Hasło */}
    <div className="mb-3">
        <label htmlFor="password1" className="form-label">
            {isTranslated ? 'Kennwort' : 'Hasło'}:
        </label>
        <input
            type={showPassword ? 'text' : 'password'}
            className={`form-control ${errors.password1 ? 'is-invalid' : ''}`}
            id="password1"
            value={password1}
            onChange={(e) => {
                setPassword1(e.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, password1: undefined }));
            }}
        />
        {errors.password1 && errors.password1.map((error, index) => (
            <div key={index} className="invalid-feedback">
                {error}
            </div>
        ))}
    </div>

    {/* Pole Potwierdź Hasło */}
    <div className="mb-3">
        <label htmlFor="password2" className="form-label">
            {isTranslated ? 'Kennwort bestätigen' : 'Potwierdź hasło'}:
        </label>
        <input
            type={showPassword ? 'text' : 'password'}
            className={`form-control ${errors.password2 ? 'is-invalid' : ''}`}
            id="password2"
            value={password2}
            onChange={(e) => {
                setPassword2(e.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, password2: undefined }));
            }}
        />
        {errors.password2 && errors.password2.map((error, index) => (
            <div key={index} className="invalid-feedback">
                {error}
            </div>
        ))}
    </div>

    {/* Przełącznik "Pokaż hasło" */}
    <div className="mb-3 form-check">
        <input
            type="checkbox"
            className="form-check-input"
            id="showPasswordCheckbox"
            checked={showPassword}
            onChange={() => setShowPassword(!showPassword)}
        />
        <label className="form-check-label" htmlFor="showPasswordCheckbox">
            {isTranslated ? 'Passwort anzeigen' : 'Pokaż hasło'}
        </label>
    </div>

    {/* Pole Imię */}
    <div className="mb-3">
        <label htmlFor="firstName" className="form-label">
            {isTranslated ? 'Vorname' : 'Imię'}:
        </label>
        <input
            type="text"
            className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
            id="firstName"
            value={firstName}
            onChange={(e) => {
                setFirstName(e.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, first_name: undefined }));
            }}
        />
        {errors.first_name && errors.first_name.map((error, index) => (
            <div key={index} className="invalid-feedback">
                {error}
            </div>
        ))}
    </div>

    {/* Pole Nazwisko */}
    <div className="mb-3">
        <label htmlFor="lastName" className="form-label">
            {isTranslated ? 'Nachname' : 'Nazwisko'}:
        </label>
        <input
            type="text"
            className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
            id="lastName"
            value={lastName}
            onChange={(e) => {
                setLastName(e.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, last_name: undefined }));
            }}
        />
        {errors.last_name && errors.last_name.map((error, index) => (
            <div key={index} className="invalid-feedback">
                {error}
            </div>
        ))}
    </div>

    {/* Polityka prywatności */}
    <div className="mb-3 form-check">
        <input type="checkbox" className="form-check-input" id="termsCheckbox" required />
        <label className="form-check-label" htmlFor="termsCheckbox">
            {isTranslated ? 'Ich akzeptiere die Datenschutzrichtlinien und die Nutzungsbedingungen' : 'Akceptuję zasady Polityki Prywatności i Regulamin Użytkownika'}
        </label>
    </div>

    {/* Przycisk rejestracji */}
    <div className='w-100 d-flex justify-content-center my-5'>
        <button type="submit" className="btn btn-primary">
            {isTranslated ? 'Registrieren' : 'Zarejestruj się'}
        </button>
    </div>
</form>

                </div>
            </div>
        </div>
    );
}

export default Register;
