import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { useLoading } from '../config/loadingContext';
import { useTranslate } from '../config/translateContext';
import axiosInstance from '../config/axiosConfig';
import NewsletterForm from '../components/newsletter';
import { useAuth } from '../config/AuthContext';
import InstructionsComponent from '../components/instruction';
import ContactFormComponent from '../components/contactForm';
import Ankieta from '../components/ankieta';
import Registration from './register';
import _ from 'lodash';
const Main = () => {
    const [diameter, setDiameter] = useState(window.innerWidth * (window.innerWidth > 700 ? 0.4 : 1.8));
    const [showModal, setShowModal] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [reklamaSlup, setReklamaSlup] = useState([]);
    const { setIsLoading } = useLoading();
    const [workAdvertisements, setWorkAdvertisements] = useState([]);
    const [infoteka, setInfoteka] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const navigate = useNavigate();
    const mediaUrl = process.env.REACT_APP_MEDIA_URL;
    const { isTranslated } = useTranslate();
    const { isAuthenticated, hasSzukajacy, hasObrotny } = useAuth();

    const [selectedItem, setSelectedItem] = useState(null);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`/reklama-slup/`);

            if (response.data && response.data.reklama_slup && Array.isArray(response.data.reklama_slup)) {
                const reklamaSlupData = _.shuffle(response.data.reklama_slup).map((item, index) => ({
                    ...item,
                    randomAngle: (360 / response.data.reklama_slup.length) * index,
                }));
                setReklamaSlup(reklamaSlupData); // Przetasowanie za pomocą lodash
                console.log('Original data:', response.data.reklama_slup);

                console.log('Shuffled data:', reklamaSlupData);
            } else {
                console.error('Unexpected response structure:', response.data);
                setReklamaSlup([]);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };


    // fetch workadvertisment
    const fetchWorkAdvertisements = async () => {
        try {
            const response = await axiosInstance.get(`/praca/`);
            setWorkAdvertisements(response.data.results.work_advertisements);
            console.log('praca', response.data.results.work_advertisements);
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    };
    const fetchInfoteka = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/infoteka/`);
            // Assuming you want to access the "newest" array
            if (response.data && Array.isArray(response.data.newest)) {
                setInfoteka(response.data.newest);
            } else {
                console.error('Unexpected response structure:', response.data);
                setInfoteka([]);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setInfoteka([]);
        }
    };



    const fetchProfile = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/profile/`);
            // Assuming you want to access the "newest" array
            if (response.data && Array.isArray(response.data.profile)) {
                setProfiles(response.data.profile);
            } else {
                console.error('Unexpected response structure:', response.data);
                setProfiles([]);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setProfiles([]);
        }
    };



const handleImageClick = (src, e, item) => {
    e.stopPropagation();
    e.preventDefault();
    setCurrentImage(src);
    setSelectedItem(item);
    setShowModal(true);
};


    const handleClose = () => {
        setShowModal(false);
    };

    const handleResize = () => {
        setDiameter(window.innerWidth * (window.innerWidth > 700 ? 0.2 : 0.2));
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        fetchData();
        fetchWorkAdvertisements();
        fetchInfoteka();
        fetchProfile();
        setIsLoading(false);
    }, []);

    const radius = diameter / 2; // Define radius here


    useEffect(() => {
    const spinContainer = document.querySelector(".spin-container");
    let angle = 0;

    const rotate = () => {
        angle = (angle - 0.1) % 360;

        spinContainer.style.transform = `rotateY(${angle}deg)`;
        requestAnimationFrame(rotate);
    };

    // Uruchom tylko na smartfonach
    if (window.innerWidth <= 700) {
        requestAnimationFrame(rotate);
    }
}, []);



    return (

        <div className='section'>



            <span className='big-text-obrotni'>OBROTNI.DE</span>
            <div className="slup">
                <img src='img/slup.png' className="central-image" alt="Central" />
                <div id="spin-container" className="spin-container">
                    {reklamaSlup.map((item) => (
                        <div
                            key={item.id}
                            className="orbit-image-container"
                            style={{
                                transform: `rotateY(${item.randomAngle}deg) translateZ(${radius}px)`,
                                position: 'absolute',
                                top: '40%',
                                left: '50%',
                                transformOrigin: '0 0',
                                width: '300px',
                                height: '650px',
                            }}
                        >
                            <img
                                src={`${process.env.REACT_APP_API_BASE_URL}${item.image}`}
                                alt={`img-${item.id}`}
                                onClick={(e) => handleImageClick(`${process.env.REACT_APP_API_BASE_URL}${item.image}`, e, item)}
                                style={{
                                    cursor: 'pointer',
                                    transition: 'transform 0.5s',
                                    width: '200px', // Adjust the size of the orbiting images
                                    height: '300px',
                                }}
                                loading='lazy'
                            />
                                <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
      {selectedItem && (
                        <a href={selectedItem.url}>
                            {isTranslated ? "Besuchen Sie die Seite" : "Odwiedź stronę"}
                            <span className='purple'> {selectedItem.name}</span>
                        </a>
                    )}         </Modal.Header>
                <Modal.Body>
                    <img src={currentImage} alt="Preview" style={{ width: '100%' }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {isTranslated ? 'Schließen' : 'Zamknij'}
                    </Button>
                </Modal.Footer>
            </Modal>
                        </div>
                    ))}
                </div>
                <div className='row'>
                    <p className='text-danger'><strong></strong></p>
                </div>
            </div>
    <section className="bsb-cta-2 py-5">
      <div className="container">
        <div
          className="card border-0 rounded-3 overflow-hidden text-center"
       style={{
  backgroundImage: "url('/img/crowd.png')",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  '--bsb-overlay-opacity': 0.9,
  '--bsb-overlay-bg-color': 'var(--bs-primary-rgb)',

}}

        >
          <div className="card-body-main">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-10 col-xl-8 col-xxl-7">
             <h2 className="h5 mb-4 purple text-uppercase">
  {isTranslated
    ? "Lassen Sie uns unsere eigenen sozialen Medien erstellen!"
    : "Stwórzmy swoje media społecznościowe!"}
</h2>

              <h2 className="display-4 text-white mb-5">
  {isTranslated
    ? "Suchen Sie nicht nach Kunden und Kontakten! Erstellen Sie ein Konto, optimieren Sie es und lassen Sie sich finden!"
    : "Nie szukaj klientów i kontaktów! Stwórz konto, dopracuj je i daj się odnaleźć!"}
</h2>

               <a href="/infoteka/o-projekcie-obrotni/" className="btn btn-light bsb-btn-3xl rounded mb-0 text-nowrap">
  {isTranslated ? "Wie funktioniert das?" : "Jak to działa?"}
</a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>












            {/* work advertisement */}
            <div className='container vh10'>

                <h1>{isTranslated ? 'ARBEIT' : 'PRACA'}</h1>

                <div className='container work-all my-5'>

                    <div className='scroll-container'>
                        {workAdvertisements && workAdvertisements.map((adv, index) => (
                            <Link to={`/praca/${adv.id}/`}>
                                <div className='card neumorphism' key={adv.id}>
                                    <img src={`${mediaUrl}${adv.img}`} className="card-img-top" alt={adv.title} />
                                    <div className='card-category-work'>
                                        <strong>{isTranslated ? adv.job.name_de : adv.job.name}</strong>
                                    </div>
                                    <div className='card-body'>
                                        <h5 className='card-title'>
                                            {adv.city.name}
                                        </h5>




                                    </div>

                                </div>        </Link>
                        ))}
                    </div>

                </div>
            </div>

            {/* infoteka */}
            <div className='container vh30'>
                {/* <span className='big-text'>INFOTEKA</span> */}
                <h1>{isTranslated ? 'INFOTHEK' : 'INFOTEKA'}</h1>

                <div className='container infoteka-all my-5'>

                    <div className='scroll-container'>

                        {infoteka.map((post, index) => (

                            <Link to={`/infoteka/${post.slug}`}>
                                <div className='card neumorphism card-info '>
                                    <img src={`${mediaUrl}${post.img}`} className="card-img-top w-100 p-0" alt={post.title} />
                                    <div className='card-category'>
                                        <strong>{isTranslated ? post.presscategory.name_de : post.presscategory.name}</strong>
                                    </div>
                                    <div className='card-body'>
                                        <p className='card-title'>
                                            {post.title}
                                        </p>

                                    </div>
                                </div>
                            </Link>

                        ))}
                    </div>
                </div>
            </div>
            {/* profile */}
            <div className='container nowi mb-5'>
                <h1>{isTranslated ? 'NEUE OBROTNI' : 'NOWI OBROTNI'}</h1>

                <div className='row obrotni-all'>
                    <div className='scroll-container'>
                        {profiles.map((profil, index) => (
                            <Link to={`/profile/${profil.slug}/`}>
                                <div className='card neumorphism card-obrotni' key={profil.id}>
                                    <div className='card-image'>
                                        <img src={`${mediaUrl}${profil.profilimg}`} className="card-img-top w-100 p-5" alt={profil.title} />
                                    </div>
                                    <div className='card-category-obrotni'>
                                        <strong>{profil.job ? profil.job.name : profil.name}</strong>
                                    </div>

                                </div></Link>
                        ))}
                    </div>
                </div>
                <Registration />
                <Ankieta />
                <ContactFormComponent />
                <NewsletterForm />
            </div>




         <style jsx>{`
    /* Ogólne style */
    .slup {
        position: relative;
        width: 300px; /* Stała szerokość słupa */
        height: 700px; /* Stała wysokość słupa */
        margin: auto;
        perspective: 1500px;
        perspective-origin: center;
        top: 50%;
        transform: translateY(-50%); /* Wyśrodkowanie pionowe */
    }

    .central-image {
        position: absolute;
        width: 250px;
        height: 650px;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .spin-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 30%; /* Wyśrodkowanie spin-container */
        left: 0%;
        transform-style: preserve-3d;
        animation: spin 20s infinite linear;
    }

    .orbit-image-container {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 300px; /* Stała szerokość reklamaSłup */
        height: 650px; /* Stała wysokość reklamaSłup */
        transform-origin: center;
        transform: translate(-50%, -50%) rotateY(0deg) translateZ(300px);
        backface-visibility: hidden;
    }

    /* Dodaj animację */
    @keyframes spin {
        from {
            transform: rotateY(0deg);
        }
        to {
            transform: rotateY(-360deg);
        }
    }
`}</style>


        </div>
    );
};

export default Main;
