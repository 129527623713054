import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslate } from '../config/translateContext';

const AnzeigSucces = () => {
    const { isTranslated } = useTranslate();

    return (
        <Container className="vh-50 py-5 d-flex justify-content-center align-items-center vh10 neumorphism">
            <Row>
                <Col className="text-center">
                    <h2 className='text-success'>
                        {isTranslated ? 'Erfolg! Ihre Anzeige wurde erfolgreich hinzugefügt!' : 'Sukces! Udało ci się dodać ogłoszenie!'}
                    </h2>
                    <p>
                        {isTranslated ? 'Viel Erfolg bei der Suche nach den perfekten Mitarbeitern!' : 'Życzymy powodzenia w znalezieniu idealnych pracowników!'}
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default AnzeigSucces;
