import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, LinearScale, CategoryScale, BarElement } from 'chart.js';
import axiosInstance from '../config/axiosConfig';
import { useAuth } from '../config/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css'; // Upewnij się, że Bootstrap jest zaimportowany
import { useTranslate } from '../config/translateContext';
Chart.register(LinearScale, CategoryScale, BarElement);

const Ankieta = () => {
  const [chartData, setChartData] = useState({});
  const [showAlert, setShowAlert] = useState(false); // Stan do zarządzania widocznością alertu
  const { isAuthenticated, hasObrotny, hasSzukajacy, profileId } = useAuth();
  const { isTranslated, toggleTranslate } = useTranslate();
  const [didVoted, setDidVoted] = useState(false);

useEffect(() => {
  const fetchVoteData = async () => {
    try {
      const response = await axiosInstance.get("/vote-count/");
      const data = response.data;
      const did_voted = response.data.you_voted;
      console.log('voted', did_voted);
      setDidVoted(did_voted);

      // Pobranie głosów z `vote_counts`
      const voteCounts = data.vote_counts;
      const labels = voteCounts.map((item) => item.question);
      const votes = voteCounts.map((item) => item.total_votes);

      // Ustawienie danych wykresu
      setChartData({
        labels: labels,
        datasets: [
          {
            label: "Number of Votes",
            data: votes,
            backgroundColor: "rgba(26, 93, 150, 0.8)",
            borderColor: "rgba(26, 93, 150, 1)",
            borderWidth: 1,
          },
        ],
      });

      // Sprawdzenie, czy użytkownik już głosował
      if (data.you_voted) {
        console.log("Użytkownik już oddał głos.");
        // Możesz tutaj ustawić stan lub zmienić interfejs użytkownika
      } else {
        console.log("Użytkownik jeszcze nie głosował.");
      }
    } catch (error) {
      console.error("Error fetching vote data:", error);
    }
  };

  fetchVoteData();
}, []);



  const handlePost = async (questionId) => {
    try {
      const response = await axiosInstance.post('/votes/', {
        user: profileId,
        question: questionId,
      });

      if (response.status === 201) {
        console.log('Głos oddany pomyślnie!');
        setShowAlert(true); // Ustaw stan alertu na true
        // Opcjonalnie: możesz również zresetować dane wykresu lub zaktualizować je
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error('Już oddano głos na to pytanie.');
      } else {
        console.error('Wystąpił błąd, spróbuj ponownie.', error);
      }
    }
  };

  return (
    <div className="Ankieta vh10 neumorphism px-5">
      <h1 className='my-5'>{isTranslated ? 'Das ist nicht alles!' : 'To nie wszystko!'}</h1>
      <p className='purple'>{isTranslated ? 'Dieses Portal wurde für dich erstellt! Deine Meinung ist uns wichtig! Was machen wir als Nächstes?' : 'Ten portal powstał dla Ciebie! Twoja opinia jest dla nas ważna! Co robimy następne?'}</p>
      <p>{isTranslated ? 'Wir haben weitere Anwendungen vorbereitet, aber wir müssen sie nacheinander einführen. Sag uns, welche dir am meisten helfen würde!' : 'Mamy przygotowane kolejne aplikacje, ale musimy je wprowadzać jedna po drugiej. Powiedz nam, która przydałaby ci się najbardziej!'}</p>
      <p>{isTranslated ? 'Die Beschreibung der Anwendungen findest du im Tab "Obrotni"' : 'Opis aplikacji znajdziesz w zakładce "Obrotni"'}</p>
      <p><em>{isTranslated ? '*Um abzustimmen, musst du mindestens Suchender sein' : '*aby zagłosować musisz być przynajmniej Szukającym'}</em></p>

      {showAlert && (
        <div className="alert alert-success" role="alert">
              {isTranslated ? "Vielen Dank für Ihre Stimme!" : "Dziękujemy za dodanie głosu!"}
        </div>
      )}

<ul className="list-unstyled mt-4">
  {didVoted ? (
    <p>{isTranslated ? 'Vielen Dank für Ihre Stimme. Abonnieren Sie unseren Newsletter, um gut informiert zu sein.' : 'Dziękujemy za oddanie głosu. Zapisz się do naszego Newslettera, aby być dobrze poinformowanym.'}</p>
  ) : (
    chartData.labels && chartData.labels.length > 0 ? (
      chartData.labels.map((label, index) => (
        <li key={index} className="d-flex justify-content-between align-items-center my-2">
          <span>{label}</span>
          {isAuthenticated && (hasSzukajacy || hasObrotny) ? (
            <button
              className="btn btn-primary"
              onClick={() => handlePost(index + 1)}
            >
              {isTranslated ? "Abstimmen" : "Zagłosuj"}
            </button>
          ) : null}
        </li>
      ))
    ) : (
      <p>Loading chart data...</p>
    )
  )}
</ul>


      <div style={{ width: "100%", margin: "0 auto", marginTop: "50px" }}>
        {chartData.labels ? (
          <Bar
          className='pb-5'
            data={chartData}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        ) : (
          <p>Loading chart data...</p>
        )}
      </div>
    </div>
  );
};

export default Ankieta;
