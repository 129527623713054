// Base URL from environment variable
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const mediaUrl = process.env.REACT_APP_MEDIA_URL;
// Define your API endpoints
const API_ENDPOINTS = {
  base: BASE_URL,
  subscribers: `${BASE_URL}/api/subscribers/`, // Newsletter endpoint
  intro: `${BASE_URL}/`, 
 
};

export default API_ENDPOINTS;
