import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslate } from '../config/translateContext';
import axiosInstance from '../config/axiosConfig';
import { AuthContext } from '../config/AuthContext';  
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// Modal component
function Img1(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body className='d-flex justify-content-center'>
        <img src={props.imgSrc} alt="Preview" className='img-fluid' />
      </Modal.Body>

    </Modal>
  );
}

// Profile component
const Profile = () => {
  const { user, isAuthenticated, logout, hasSzukajacy, hasObrotny } = useContext(AuthContext);
  const { slug } = useParams();
  const [profile, setProfile] = useState(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const [modalShow, setModalShow] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const { isTranslated } = useTranslate();
  const [isJob, setIsJob] = useState(false);
  const [myAdv, setMyAdv] = useState([]);
  const [posts, setPosts] = useState([]);
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const navigate = useNavigate();
  const [isContactAdded, setIsContactAdded] = useState(false);
  const [isContactExist, setIsContactExist] = useState(false);


  const isProfileEmpty = () => {
    return !profile.name && 
           !profile.surname && 
           (!profile.job || !profile.job.name) && 
           !profile.tel && 
           (!profile.city || !profile.city.name) && 
           (!profile.land || !profile.land.name) && 
           !profile.description;
  };



  const fetchProfileData = async () => {
    try {
      const response = await axiosInstance.get(`/profile/${slug}/`);
      setProfile(response.data.profile);
      setMyAdv(response.data.my_adv);
      setPosts(response.data.posts);
      setIsContactExist(response.data.contact_exists); // Ustawienie stanu isContactExist

      // Jeśli kontakt już istnieje, ustaw isContactAdded na true
      if (response.data.contact_exists) {
        setIsContactAdded(true);
      }

    } catch (error) {
      setError(`Error fetching profile data: ${error.response ? error.response.statusText : error.message}`);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, [slug]);

  const handleImageClick = (imgSrc) => {
    setSelectedImg(imgSrc);
    setModalShow(true);
  };

  const handleButtonClick = (slug) => {
    navigate(`/infoteka/${slug}`);
  };
  const handleWorkButtonClick = (slug) => {
    navigate(`/infoteka/${slug}`);
  };


  
  if (error) {
    return <div>{error}</div>;
  }

  if (!profile) {
    return (
      <div className='w-100 d-flex justify-content-center h-100 ontop position-fixed t-0 cloude'>
        <img src='/img/logo.png' className='rotating-object align-self-center loader' alt="Loader" />
      </div>
    );
  }
  








  const handleAddContact = async () => {
    try {
        const response = await axiosInstance.post(`/profile/${slug}/`);
        console.log(response);

        if (response.status === 201) {
            setMessage(isTranslated ? 'Kontakt wurde erfolgreich hinzugefügt. Sie finden ihn in Ihrem Adressbuch.' : 'Kontakt został dodany pomyślnie. Znajdziesz go w książce adresowej.');
            setError(null);
            setIsContactAdded(true);
        }
    } catch (error) {
        if (error.response) {
            console.error('Error response:', error.response); // Dodaj logi błędów
            if (error.response.status === 400) {
                setError(isTranslated ? 'Dieser Kontakt existiert bereits in Ihrem Buch.' : 'Ten kontakt już istnieje w Twojej książce.');
            } else if (error.response.status === 500) {
                setError(isTranslated ? 'Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.' : 'Wystąpił błąd serwera. Spróbuj ponownie później.');
            } else {
                setError(isTranslated ? 'Ein unbekannter Fehler ist aufgetreten.' : 'Wystąpił nieznany błąd.');
            }
        } else {
            console.error('Network error:', error); // Dodaj logi błędów
            setError(isTranslated ? 'Netzwerkfehler. Bitte überprüfen Sie Ihre Verbindung.' : 'Błąd sieci. Sprawdź swoje połączenie.');
        }
        setMessage(null);
    }
};




  return (
    
    <div className="container  profile-general section">
        <div>
  
            {message && <div className="alert alert-success mt-3">{message}</div>}
            {error && <div className="alert alert-danger mt-3">{error}</div>}
        </div>
      
<div className='row'>
<div className="profile-header col-lg-6 col-12">
        <img src={profile.profilimg} alt={profile.user.username} className="profile-picture" />
        {isAuthenticated ? (
          (hasObrotny || hasSzukajacy) ? (
            isContactAdded ? (
              <button className='btn btn-success px-5' disabled>
                <i className="bi bi-person-check-fill"></i> {isTranslated ? 'Kontakt bereits gespeichert' : 'Kontakt już zapisany'}
              </button>
            ) : (
              <button onClick={handleAddContact} className='btn btn-primary px-5'>
                <i className="bi bi-person-plus-fill"></i> {isTranslated ? 'Zu Kontakten hinzufügen' : 'Dodaj do kontaktów'}
              </button>
            )
          ) : (
            <button className='btn btn-secondary px-5' disabled>
              <i className="bi bi-person-plus-fill"></i> {isTranslated ? 'Zu Kontakten hinzufügen' : 'Dodaj do kontaktów'}
            </button>
          )
        ) : (
          <button className='btn btn-secondary px-5' disabled>
            <i className="bi bi-person-plus-fill"></i> {isTranslated ? 'Zu Kontakten hinzufügen' : 'Dodaj do kontaktów'}
          </button>
        )}
</div>
<div className='col-md-6 col-12 d-flex align-items-center'>
<div className='d-block'>

<h1>{profile.name} {profile.surname}</h1>
{isProfileEmpty() ? (
  <p>
    {isTranslated 
      ? 'Profil wurde noch nicht vervollständigt'
      : 'Profil nie został dopracowany'}
  </p>
) : (
  <div className="profile-details">
    {profile.job?.name && <h2>{profile.job.name}</h2>}
    {profile.user?.email && <p>{profile.user.email}</p>}
    {profile.tel && <p>{profile.tel}</p>}
    <p>
      {profile.city?.name && profile.city.name}
      {profile.city?.name && profile.land?.name && ', '}
      {profile.land?.name && profile.land.name}
    </p>
    {(profile.kod || profile.miasto) && (
      <p>{profile.kod} {profile.miasto}</p>
    )}
    {profile.website && (
      <p><strong><a href={profile.website}>{profile.website}</a></strong></p>
    )}
  </div>
)}
</div>
</div>    
</div>

      <div className="about-me vh10" dangerouslySetInnerHTML={{ __html: profile.description }} />

      <div className="vh30">
        <h1>{isTranslated ? 'Gallery' : 'Galeria'}</h1>
        <div className="gallery">
          {profile.img1 && (
            <div className="gallery-item">
              <img
                src={profile.img1}
                alt={profile.slug}
                className="img-fluid rounded gallery-img"
                onClick={() => handleImageClick(profile.img1)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}
          {profile.img2 && (
            <div className="gallery-item">
              <img
                src={profile.img2}
                alt={profile.slug}
                className="img-fluid rounded gallery-img"
                onClick={() => handleImageClick(profile.img2)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}
          {profile.img3 && (
            <div className="gallery-item">
              <img
                src={profile.img3}
                alt={profile.slug}
                className="img-fluid rounded gallery-img"
                onClick={() => handleImageClick(profile.img3)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}
          {profile.img4 && (
            <div className="gallery-item">
              <img
                src={profile.img4}
                alt={profile.slug}
                className="img-fluid rounded gallery-img"
                onClick={() => handleImageClick(profile.img4)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}
        </div>
      </div>

      <Img1
        show={modalShow}
        onHide={() => setModalShow(false)}
        imgSrc={selectedImg}
        className='img-fluid'
      />
{myAdv && myAdv.length > 0 && (
    <div className='row work-all my-5 pb-3'>
        <h2>{isTranslated ? 'Obrotni-Anzeigen in der Anwendung "Arbeit"' : 'Ogłoszenia Obrotnego w aplikacji "Praca"'}</h2>
        <div className='scroll-container'>
            {myAdv.map((adv, index) => (
                <div className='card neumorphism card-info' key={adv.id}>
                    <img src={`${mediaUrl}${adv.img}`} className="card-img-top" alt={adv.title} />
                    <div className='card-category-work'>
                        <strong>{isTranslated ? adv.job.name_de : adv.job.name}</strong>
                    </div>
                    <div className='card-body'>
                        <h5 className='card-title'>
                            {adv.city.name}
                        </h5>
                        <p className='card-text'>
                            {adv.intro}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    </div>
)}






{posts && posts.length > 0 && (
        <div className='row work-all my-5 pb-3'>
          <h2 className='my-5'>{isTranslated ? 'Obrotni-Anzeigen in der Anwendung "Infoteka"' : 'Artykuły Obrotnego w aplikacji "Infoteka"'}</h2>
          <div className='scroll-container'>
{posts.map((post) => (
    <div
        className='card neumorphism card-info'
        key={post.id}
        onClick={() => handleButtonClick(post.slug)}
        style={{ cursor: 'pointer' }}
    >
        <img src={`${mediaUrl}${post.img}`} className="card-img-top" alt={post.title} />
        <div className='card-body d-block'>
            <h5 className='card-title'>
                <strong>{post.title}</strong>
            </h5>
        </div>
    </div>
))}

          </div>
        </div>
      )}



    </div>
  );
};

export default Profile;
