import React, { createContext, useContext, useState } from 'react';

const TranslateContext = createContext();

export const useTranslate = () => useContext(TranslateContext);

export const TranslateProvider = ({ children }) => {
  const [isTranslated, setIsTranslated] = useState(false);

  const toggleTranslate = () => setIsTranslated(!isTranslated);

  return (
    <TranslateContext.Provider value={{ isTranslated, setIsTranslated, toggleTranslate }}>
      {children}
    </TranslateContext.Provider>
  );
};

export default TranslateContext;
