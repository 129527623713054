import React, { useState } from 'react';
import axiosInstance from "../config/axiosConfig";
import Alert from 'react-bootstrap/Alert';
import { useTranslate } from '../config/translateContext';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function PasswordResetRequest() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { isTranslated } = useTranslate(); // Użycie kontekstu tłumaczeń

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('api/users/reset_password/', {
        email: email // upewnij się, że jest przekazywane jako klucz "email"
      });
      setMessage(isTranslated ? 'Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet.' : 'Link do resetowania hasła został wysłany na Twój adres email.');
    } catch (error) {
      setError(isTranslated ? 'Es gab ein Problem beim Senden der E-Mail.' : 'Wystąpił problem przy wysyłaniu emaila.');
    }
  };

  return (
    <div className="vh30 container">
      <h2 className="mb-4">{isTranslated ? 'Passwort zurücksetzen' : 'Resetowanie hasła'}</h2>
      {message && <Alert variant='success'>{message}</Alert>}
      {error && <Alert variant='danger'>{error}</Alert>}
      <Form onSubmit={handleSubmit} className="w-50 mx-auto">
        <Form.Group controlId="formEmail" className="mb-3">
          <Form.Label>{isTranslated ? 'E-Mail:' : 'Email:'}</Form.Label>
          <Form.Control
            type="email"
            placeholder={isTranslated ? 'Geben Sie Ihre E-Mail-Adresse ein' : 'Wprowadź swój adres email'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          {isTranslated ? 'Passwort zurücksetzen' : 'Zresetuj hasło'}
        </Button>
      </Form>
    </div>
  );
}

export default PasswordResetRequest;
