import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslate } from '../config/translateContext';
import Loader from './Loader';

const LandList = ({ onSelectLand }) => {
  const [lands, setLands] = useState([]);
  const [error, setError] = useState(null);
  const { isTranslated } = useTranslate();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/landy/`)
      .then(response => {
        setLands(response.data);
      })
      .catch(error => {
        setError(error);
      });
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className='d-block'>
      <h1 className='text-end'>{isTranslated ? 'Wählen Sie Land' : 'Wybierz Land'}</h1>
      <ul className='list-unstyled text-end'>
        {lands.map(land => (
          <li key={land.id} onClick={() => onSelectLand(land)}>
            {isTranslated ? land.name_de : land.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LandList;
