import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import LandList from '../components/myMap';
import { Modal, Button } from 'react-bootstrap';
import { useTranslate } from '../config/translateContext';

const Landy = () => {
  const [selectedLand, setSelectedLand] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { isTranslated } = useTranslate();

  const handleSelectLand = (land) => {
    setSelectedLand(land);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModal(false);
  };

  const handleConfirm = () => {
    if (selectedLand) {
      window.location.href = `/landy/${selectedLand.slug}`;
    }
  };

  const MapUpdater = ({ land }) => {
    const map = useMap();
    useEffect(() => {
      if (land) {
        map.setView([land.latitude, land.longitude], 10);
      }
    }, [land, map]);
    return null;
  };

  return (
    <div className='row vh30 landy'>
      <div className='col-lg-6 landy-col glass'>
        <LandList onSelectLand={handleSelectLand} />
      </div>
      <div className='col-lg-6 d-flex align-items-center'>
        <MapContainer
          center={[51.1657, 10.4515]}
          zoom={6}
          scrollWheelZoom={false}
          style={{ height: '500px', width: '100%' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {selectedLand && <MapUpdater land={selectedLand} />}
        </MapContainer>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Body className='py-5'>
          {selectedLand ? 
            isTranslated 
              ? `Möchten Sie zur Seite ${selectedLand.name} gehen?` 
              : `Czy chcesz przejść do strony ${selectedLand.name}?`
            : 'Laden...'}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" className='text-white' onClick={handleCloseModal}>
            {isTranslated ? 'Nein' : 'Nie'}
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            {isTranslated ? `Gehe zu ${selectedLand?.name}` : `Przejdź do ${selectedLand?.name}`}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Landy;
