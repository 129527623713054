import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
function Impressum() {
    const [impressum, setImpressum] = useState(null);
    const [error, setError] = useState('');
    const { slug } = useParams();
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/impressum/${slug}/`;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(apiUrl);
                setImpressum(response.data.impressum);
            } catch (error) {
                console.error("Failed to fetch data:", error);
                setError("Failed to load data.");
            }
        };

        fetchData();
    }, [slug, apiUrl]);

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!impressum) {
        return <p>Loading...</p>;
    }

    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const formattedDate = new Date(impressum.date).toLocaleDateString(undefined, dateOptions);

    return (
        <div className="container  blue my-5">
            <div className="row my-5">
                <h1>Impressum</h1>
            </div>
            <div className="my-5 row">
                <h2>{formattedDate}</h2>
                <div dangerouslySetInnerHTML={{ __html: impressum.text }} className="my-5" />
            </div>
        </div>
    );
}

export default Impressum;
