// Loader.js
import React from 'react';
import { useLoading } from '../config/loadingContext';

const Loader = () => {
  const { isLoading } = useLoading();

  if (!isLoading) return null;

  return (
    <div className='w-100 d-flex justify-content-center h-100 ontop  position-fixed t-0 cloude'>
      <img src='/img/logo.png' className='rotating-object align-self-center loader'/>
    </div>
  );
};

export default Loader;
