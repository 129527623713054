import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../config/AuthContext';
import { useParams } from 'react-router-dom';
import { useLoading } from '../config/loadingContext';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../config/axiosConfig';





const LebenslaufDetail = () => {
    const { id } = useParams();
    const [lebenslauf, setLebenslauf] = useState(null);
    const [error, setError] = useState('');
    const { isAuthenticated } = useContext(AuthContext);
    const { isLoading, setIsLoading } = useLoading();
    const navigate = useNavigate();


    useEffect(() => {
        const fetchDetails = async () => {
            setIsLoading(true);  // Set loading state to true before async operation
            try {
                const token = localStorage.getItem('access_token');
                if (!token) {
                    throw new Error('No access token');
                }

                const response = await axiosInstance.get(`/lebenslauf/${id}/`, {
                    headers: { Authorization: `Bearer ${token}` }
                  });
                  
                setLebenslauf(response.data.lebenslauf);
            } catch (err) {
                setError('Failed to fetch details. Please try again later.');
                console.error(err);
            } finally {
                setIsLoading(false);  // Set loading state to false after async operation
            }
        };

        if (isAuthenticated) {
            fetchDetails();
        }
    }, [id, isAuthenticated, setIsLoading]);

    const handleTranslate = async (e) => {
        e.preventDefault();
        setIsLoading(true);  // Set loading state to true before async operation
        try {
            const token = localStorage.getItem('access_token');
            if (!token) {
                throw new Error('No access token');
            }

            const response = await axiosInstance.post(`/lebenslauf/${id}/`, {}, {
                headers: { Authorization: `Bearer ${token}` }
              });
              
            setLebenslauf(response.data.lebenslauf);
            if (response.data.id) {
            navigate(`/cv/de/${response.data.id}`);  // Redirect to the new URL using the returned ID
        }
            
        } catch (err) {
            setError('Failed to translate details. Please try again later.');
            console.error(err);
        } finally {
            
            setIsLoading(false);  // Set loading state to false after async operation
        }
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (isLoading || !lebenslauf) {
        return <Loader />;  // Show loader if loading or lebenslauf is not yet loaded
    }

    return (
        <div className="container vh10">
            <div className='row'>
                <div className='col-lg-2 ps-4 py-0 pe-0 d-flex bg-dark justify-content-end'>
                    <img src={lebenslauf.img ? lebenslauf.img : 'img/logo.png'} className='w-100 bg-white'/>
                </div>
                <div className='col-lg-10 p-5 bg-body-tertiary'>
                    <h2 className='display-3'>{lebenslauf.first_name} {lebenslauf.last_name}</h2>
                </div>   
            </div>
            <div className='row'>
                <div className='col-md-4 mt-5'>
                    <ul className='list-unstyled'>
                    <li>geb. {lebenslauf.date_of_birth}</li>
                    <li>{lebenslauf.my_city}</li>
                    <li>{lebenslauf.strasse} {lebenslauf.haus_nr}</li>
                    </ul>
                    <p><strong>{lebenslauf.phone}</strong></p>
                    <p><strong>{lebenslauf.email}</strong></p>
                <h2>Kursy i certyfikaty</h2>
                    <ul className='list-unstyled'>
                        {lebenslauf.skills.map((skill, index) => (
                            <li className='bg-light my-2 py-2  bg-gradient' key={index}>{skill.title} <br/> {skill.level} </li>
                        ))}
                    </ul>
                    <h2>Języki</h2>
                    <ul className='list-unstyled'>
                        {lebenslauf.languages.map((lang, index) => (
                            <li className='bg-light my-2 py-2  bg-gradient' key={index}>{lang.name}- {lang.proficiency} </li>
                        ))}
                    </ul>
                    <p><strong>prawo jazdy kat. {lebenslauf.driving_license}</strong></p>
                    <p><strong>Własny samochód: {lebenslauf.is_auto ? 'tak' : 'nie'}</strong></p>
                </div>
                <div className='col-md-8'>
                    <h2 className='display-3 my-5'>Edukacja</h2>
                    {lebenslauf.education.map((edu, index) => (
                        <div className='my-5 border-bottom-orange' key={index}>
                            <h3>{edu.institution}</h3>
                            <p>{edu.degree}</p>
                            <p> {edu.field_of_study}</p>
                            <p>od {edu.start_date} do {edu.end_date}</p>
                        </div>
                    ))}
                    <h2 className='display-3 my-5'>Doświadczenie</h2>
                    {lebenslauf.experience.map((exp, index) => (
                        <div className='my-5 border-bottom-orange' key={index}>
                            <h3>{exp.company}</h3>
                            <p>{exp.position}</p>
                            <p>od {exp.start_date} do {exp.end_date}</p>
                        </div>
                    ))}
                </div>
            </div>
         <div className='row'>
         <form onSubmit={handleTranslate}>
            <button className='btn orange-button w-100' type='submit'> Przetłumacz </button>
            </form>
         </div>
        </div>
    );
};

export default LebenslaufDetail;
