import React, { useState } from 'react';
import { useTranslate } from '../config/translateContext';

const PaymentSuccess = () => {
  const { isTranslated, toggleTranslate } = useTranslate();
  return (
    <div className='container vh10'>
      <h1>{isTranslated ? 'Erfolg!' : 'Sukces!'}</h1>
      <p className='purple bold'>{isTranslated ? 'Vielen Dank, dass du bei uns bist. Du kannst jetzt unser System gemäß deinem gewählten Abonnement nutzen.' : 'Dziękujemy, że jesteś. Możesz już korzystać z naszego systemu zgodnie z wybraną przez ciebie subskrybcją'}</p>
    </div>
  );
};

export default PaymentSuccess;
