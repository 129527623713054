// CookieConsent.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../config/AuthContext';
import { useTranslate } from '../config/translateContext';

// Funkcje zarządzające zgodami użytkownika
const getCookieConsent = () => {
  const consent = localStorage.getItem('cookieConsent');
  return consent ? JSON.parse(consent) : null;
};

const setCookieConsent = (consent) => {
  localStorage.setItem('cookieConsent', JSON.stringify(consent));
};

// Komponent modalny do ustawień cookies
const CookieSettingsModal = ({ isOpen, onClose, onSave, consent, t }) => {
  const [preferences, setPreferences] = useState(consent || {});

  const handleChange = (e) => {
    setPreferences({
      ...preferences,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSave = () => {
    const updatedConsent = {
      necessary: true,
      ...preferences,
    };
    onSave(updatedConsent);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
        <h2>{t('cookieSettingsTitle')}</h2>
        <form>
          <label>
            <input type="checkbox" name="necessary" checked disabled />
            {t('necessaryCookies')}
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              name="analytics"
              checked={preferences.analytics || false}
              onChange={handleChange}
            />
            {t('analyticsCookies')}
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              name="marketing"
              checked={preferences.marketing || false}
              onChange={handleChange}
            />
            {t('marketingCookies')}
          </label>
        </form>
        <button className="btn btn-primary" onClick={handleSave}>
          {t('saveSettings')}
        </button>
        <button className="btn btn-primary" onClick={onClose}>
          {t('cancel')}
        </button>
      </div>
    </div>
  );
};

// Główny komponent banera cookies
const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [consent, setConsentState] = useState(null);
  const { isTranslated } = useTranslate(); // Używamy isTranslated do wyboru języka
  const { user } = useAuth(); // Jeśli potrzebujesz informacji o użytkowniku

  useEffect(() => {
    const existingConsent = getCookieConsent();
    if (!existingConsent) {
      setShowBanner(true);
    } else {
      setConsentState(existingConsent);
      // Załaduj skrypty na podstawie istniejących zgód
      loadScripts(existingConsent);
    }
  }, []);

  const handleAcceptAll = () => {
    const allConsent = {
      necessary: true,
      analytics: true,
      marketing: true,
    };
    setCookieConsent(allConsent);
    setConsentState(allConsent);
    setShowBanner(false);
    loadScripts(allConsent);
  };

  const handleCustomize = () => {
    setShowSettings(true);
  };

  const handleSaveSettings = (preferences) => {
    setCookieConsent(preferences);
    setConsentState(preferences);
    setShowBanner(false);
    loadScripts(preferences);
  };

  const loadScripts = (consent) => {
    if (consent.analytics) {
      // Załaduj skrypty analityczne
    }
    if (consent.marketing) {
      // Załaduj skrypty marketingowe
    }
  };

  if (!showBanner && !showSettings) return null;

  // Funkcja tłumaczeń
  const t = (key) => {
    const translations = {
      cookieNotice: {
        pl: 'Ta strona używa plików cookies w celu zapewnienia najlepszej jakości korzystania z naszego serwisu. Możesz zaakceptować wszystkie pliki cookies lub dostosować swoje preferencje.',
        de: 'Diese Website verwendet Cookies, um Ihnen das beste Erlebnis auf unserer Website zu ermöglichen. Sie können alle Cookies akzeptieren oder Ihre Einstellungen anpassen.',
      },
      acceptAll: {
        pl: 'Akceptuj wszystkie',
        de: 'Alle akzeptieren',
      },
      customizeSettings: {
        pl: 'Dostosuj ustawienia',
        de: 'Einstellungen anpassen',
      },
      cookieSettingsTitle: {
        pl: 'Ustawienia plików cookies',
        de: 'Cookie-Einstellungen',
      },
      necessaryCookies: {
        pl: 'Niezbędne pliki cookies',
        de: 'Notwendige Cookies',
      },
      analyticsCookies: {
        pl: 'Analityczne pliki cookies',
        de: 'Analyse-Cookies',
      },
      marketingCookies: {
        pl: 'Marketingowe pliki cookies',
        de: 'Marketing-Cookies',
      },
      saveSettings: {
        pl: 'Zapisz ustawienia',
        de: 'Einstellungen speichern',
      },
      cancel: {
        pl: 'Anuluj',
        de: 'Abbrechen',
      },
    };

    const language = isTranslated ? 'de' : 'pl';
    return translations[key][language] || key;
  };

  return (
    <>
      {showBanner && (
        <div style={bannerStyle}>
          <p className="blue">{t('cookieNotice')}</p>
          <div style={bannerButtonsStyle}>
            <button className="btn btn-primary" onClick={handleAcceptAll}>
              {t('acceptAll')}
            </button>
            <button className="btn btn-primary" onClick={handleCustomize}>
              {t('customizeSettings')}
            </button>
          </div>
        </div>
      )}
      <CookieSettingsModal
        isOpen={showSettings}
        onClose={() => setShowSettings(false)}
        onSave={handleSaveSettings}
        consent={consent}
        t={t}
      />
    </>
  );
};

// Style komponentów
const bannerStyle = {
  position: 'fixed',
  bottom: 0,
  width: '100%',
  minHeight: '150px',
  backgroundColor: '#ffffff',
  padding: '20px',
  boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.3)',
  zIndex: 1000,
};

const bannerButtonsStyle = {
  marginTop: '10px',
};

const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 2000,
};

const modalContentStyle = {
  backgroundColor: '#ffffff',
  padding: '30px',
  borderRadius: '5px',
  width: '500px',
  maxWidth: '90%',
};

export default CookieBanner;
