// gsap.js
import React, {  useRef } from 'react';
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
    
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

export const fadeAnimation = (element) => {
  gsap.fromTo(element,
    { opacity: 0, scale: 0.2, duration: 1 },
    { opacity: 1, scale: 1 }
  );
};

export const LeftFadeAnimation = () => {
  const elements = gsap.utils.toArray(".leftFade");
  console.log(elements); // Sprawdź, czy znajduje odpowiednie elementy
  elements.forEach(element => {
    gsap.fromTo(element, 
      {x:-50,
      opacity:0,
      duration:0.5,
    },
      {
      x: 0,
      opacity:1,
      duration: 0.5,
      scrollTrigger: {
        trigger: element,
        start: "top center",
    
    
      }
    });
  });
};




export const RightFadeAnimation = () => {
  const elements = gsap.utils.toArray(".rightFade");
  console.log(elements); // Sprawdź, czy znajduje odpowiednie elementy
  elements.forEach(element => {
    gsap.fromTo(element, 
      {x:50,
      opacity:0,
      duration:1,
    },
      {
      x: 0,
      opacity:1,
      duration: 1,
      scrollTrigger: {
        trigger: element,
        start: "top center",
    
    
      }
    });
  });
};