import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from "../config/axiosConfig";
import { useTranslate } from '../config/translateContext';

function PasswordResetConfirm() {
  const { uid, token } = useParams();  // UID i token z URL
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { isTranslated } = useTranslate(); // Dostęp do kontekstu tłumaczeń

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setMessage(isTranslated ? 'Die Passwörter stimmen nicht überein.' : 'Hasła się nie zgadzają');
      return;
    }

    try {
      const response = await axiosInstance.post('api/users/reset_password_confirm/', {
        uid: uid,
        token: token,
        new_password: newPassword,
      });

      if (response.status === 204) {
        setMessage(isTranslated ? 'Das Passwort wurde erfolgreich zurückgesetzt.' : 'Hasło zostało zresetowane pomyślnie.');
        navigate('/login');  // Przekierowanie do logowania
      }
    } catch (error) {
      setMessage(isTranslated ? 'Beim Zurücksetzen des Passworts ist ein Fehler aufgetreten.' : 'Wystąpił błąd podczas resetowania hasła.');
      console.error(error);
    }
  };

  return (
    <div className="container vh30">
      <h2>{isTranslated ? 'Passwort zurücksetzen' : 'Zresetuj hasło'}</h2>
      {message && <div className="alert alert-info">{message}</div>}
      <form onSubmit={handlePasswordReset}>
        <div className="form-group">
          <label htmlFor="newPassword">{isTranslated ? 'Neues Passwort' : 'Nowe hasło'}</label>
          <input
            type="password"
            id="newPassword"
            className="form-control"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">{isTranslated ? 'Passwort bestätigen' : 'Potwierdź hasło'}</label>
          <input
            type="password"
            id="confirmPassword"
            className="form-control"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary mt-3">
          {isTranslated ? 'Passwort zurücksetzen' : 'Zresetuj hasło'}
        </button>
      </form>
    </div>
  );
}

export default PasswordResetConfirm;
