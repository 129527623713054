import React, { useState, useEffect, useRef } from 'react';
import API_ENDPOINTS from '../utils';
import axios from 'axios';
import { gsap } from 'gsap';


import { useTranslate } from '../config/translateContext';






function NewsletterForm() {
    const [email, setEmail] = useState('');
    const [agree, setAgree] = useState(false);
    const [robot, setRobot] = useState(false); 
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [content, setContent] = useState('');
    const { isTranslated, toggleTranslate } = useTranslate();

 
    const handleSubmit = async (event) => {
        event.preventDefault();
 
        if (robot) {
            console.log('Bot detected');
            return; // Early return or handle as needed
        }
    
        try {
            const response = await fetch(API_ENDPOINTS.subscribers, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    agree, // Include 'agree' in the payload
                    // Optionally include 'robot' if it serves a different purpose
                }),
            });
    
            if (response.headers.get('Content-Type')?.includes('application/json')) {
                const data = await response.json();
                if (response.ok) {
                    setMessage('Dziękujemy! Teraz jesteśmy w kontakcie!');
                    setShowMessage(true);
                    setEmail('');
                    setAgree(false); // Reset the 'agree' checkbox state
                    // Reset 'robot' state if necessary
                } else {
                    setMessage(data.detail || 'An error occurred. Please try again.');
                }
            } else {
                if (response.ok) {
                    setMessage('Dziękujemy! Teraz jesteśmy w kontakcie!');
                    setShowMessage(true);
                    setEmail('');
                    setAgree(false); // Reset
                } else {
                    console.error('Non-JSON response received:', await response.text());
                    setMessage('An error occurred. Please try again.');
                }
            }
        } catch (error) {
            console.error('Failed to subscribe:', error);
            setMessage('An error occurred. Please try again.');
        }
    };
    
    useEffect(() => {
        const apiUrl = API_ENDPOINTS.subscribers;
        axios.get(apiUrl)
        .then(response => {
            // Zakładając, że API zwraca bezpośrednio HTML lub tekst jako 'newsletter_content'
            setContent(response.data.newsletter_content);
        })
        .catch(error => {
            console.error('Error fetching newsletter content:', error);
        });
        
    }, []);


    useEffect(() => {
        // Znajdź wszystkie elementy do animacji
        const elementsToAnimate = gsap.utils.toArray('.animatable');
        if (elementsToAnimate.length > 0) {
          gsap.fromTo(elementsToAnimate, { opacity: 0, x: -10 }, { opacity: 1, x: 0, duration: 0.5, stagger: 0.2 });
        }
      }, [isTranslated]);
    
    
    


    return (
        <>
 <div className='w-100 neumorphism d-flex align-items-center h-100 justify-content-center'>
 <form onSubmit={handleSubmit} className='newsletter  '>
                
                <div className='row newsletter-form text-sm-center d-flex align-items-center'>
                    
                    <div className='my-2'>

                <h3 className='animatable'> {isTranslated ? content.text_de : content.text }</h3>
                    
                    </div>
                </div>
                <div className='row newsletter-form  text-sm-center'>
                <div className='col-md-8 d-block'>
                                
                <input
                                type="email"
                                placeholder={isTranslated ? 'Geben Sie Ihre E-Mail-Adresse ein' : 'Wpisz swój adres email'}
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            className='w-100  my-2'
                            
                            />
            
                        <input
                            type="checkbox"
                            id="agree"
                            checked={agree} // Use checked for checkbox, not value
                            onChange={(e) => setAgree(e.target.checked)} // Update agree state based on checkbox status
                            required
                        className='mx-2'
                        />
                          <label htmlFor="agree"> {isTranslated ? 'Ich stimme der Datenschutzrichtlinie der Website zu' : 'Zgadzam się z Polityką Prywatności portalu'}</label>
                          <input
                            type="checkbox"
                            id="robot"
                            style={{ display: 'none' }} // Hide the input
                            checked={robot}
                            onChange={(e) => setRobot(e.target.checked)} // Normally, you wouldn't let the user change this, hence hidden
                        />
                </div>
                <div className='col-md-4 text-center mx-auto h-100 '>
                    
                <button type="submit" className='btn btn-outline-dark w-100 animatable'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send-fill mx-2" viewBox="0 0 16 16">
              <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z"/>
            </svg>{isTranslated ? 'Senden' : 'Zapisz się'}</button>
                    </div>
                </div>
                        </form>
 </div>
            {showMessage && (
    <div className="message-overlay neumorphism">
        <div className="message-box">
            <p>{message}</p>
            <button className='btn btn-outline-dark' onClick={() => {
                setShowMessage(false); // Hide the message overlay
                window.location.reload(); // Refresh the page
            }}>Zamknij</button>
        </div>
    </div>
)}
        </>
    );
}

export default NewsletterForm;
