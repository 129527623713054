import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom'; 
import axiosInstance from '../config/axiosConfig';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Navbar from 'react-bootstrap/Navbar';



const CityDetail = () => {
  const [cityData, setCityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const [activeCategory, setActiveCategory] = useState(null);
  const [filteredObrotni, setFilteredObrotni] = useState([]); // Nowy stan dla filtrowanych obrotni
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const menuRef = useRef(null);

  const scroll = (direction) => {
      const { current } = menuRef;
      const scrollAmount = 200;
      if (direction === 'left') {
          current.scrollBy({ left: -scrollAmount, top: 0, behavior: 'smooth' });
      } else {
          current.scrollBy({ left: scrollAmount, top: 0, behavior: 'smooth' });
      }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/stadt/${slug}/`);
        setCityData(response.data);
        setFilteredObrotni(response.data.obrotni);
        console.log("Responsestr data:", response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (loading) return <p>Loading...</p>;
  if (!cityData) return <p>No data available</p>;

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(activeCategory === categoryId ? null : categoryId);
  };

const handleJobClick = (jobId) => {
  const obrotniForJob = cityData.obrotni_jobs.filter((profil) => profil.job && profil.job.id === jobId);

  if (obrotniForJob.length > 0) {
    setFilteredObrotni(obrotniForJob);
    console.log('Filtered obrotni for job:', obrotniForJob);
  } else {
    console.log('No obrotni found for job:', jobId);
    setFilteredObrotni([]);  // Pusty wynik, gdy nie ma obrotnych dla tego jobId
  }
};

  return (
    <div className='container vh10'>
      <h1>{cityData.city.name}</h1>
      
      <h2 className='mt-5'>Kategorie:</h2>
      


      <div className='container mt-4'>
  <Navbar expand="lg" className="navbar">
    <Container className='position-relative'>
      <Row className='w-100 d-flex align-items-center p-2'>
        <div className="menu-wrapper col-md-12 col-lg-12">
          <span className="pointer left-pointer" onClick={() => scroll('left')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffca2c" className="bi bi-chevron-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
            </svg>
          </span>
          <div className="menu-item d-flex overflow-auto" ref={menuRef}>
            {cityData.categories.length > 0 ? (
              cityData.categories.map((category) => (
                <div key={category.id} className='category'>
                  <h3 onClick={() => handleCategoryClick(category.id)} className='category-name'>
                    {category.name}
                  </h3>
                </div>
              ))
            ) : (
              <p>No categories found.</p>
            )}
          </div>
          <span className="pointer right-pointer" onClick={() => scroll('right')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffca2c" className="bi bi-chevron-right" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
            </svg>
          </span>
        </div>
      </Row>
    </Container>
  </Navbar>

  <div className='d-block gap-3'>
    {activeCategory && cityData.jobs
      .filter((job) => job.category.id === activeCategory).length > 0 ? (
        cityData.jobs
          .filter((job) => job.category.id === activeCategory)
          .map((job) => (
<div key={job.id} className='job-item'>

  <button className='btn btn-primary' onClick={() => handleJobClick(job.id)}>{job.name}</button>
</div>

          ))
      ) : (
        <p>Znajdź obrotnego w tym mieście!</p>
      )
    }
  </div>
</div>
{cityData && (
  <h1 className='my-5'>Obrotni:</h1>
)}




      <div className='obrotni-all'>
        <div className='scroll-container'>
          {filteredObrotni.length > 0 ? (
            filteredObrotni.map((profil) => (<a href={`/profile/${profil.slug}/`}>
              <div className='card neumorphism card-obrotni' key={profil.id}>
                <div className='card-image'>
                  <img src={`${mediaUrl}${profil.profilimg}`} className="card-img-top" alt={profil.title} />
                </div>
                <div className='card-category-obrotni'>
                  <strong>{profil.job ? profil.job.name : profil.name}</strong>
                </div>
                <div className='card-body'>
                  <h5 className='card-title'>
                    {profil.title}
                  </h5>
                  <p className='card-text'>
                    {profil.description}
                  </p>
                                  </div>
              </div></a>
            ))
          ) : (
            <p>No profiles found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CityDetail;
