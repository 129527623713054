import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import axiosInstance, { setupInterceptors } from './axiosConfig';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [hasObrotny, setHasObrotny] = useState(false);
  const [hasSzukajacy, setHasSzukajacy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userSlug, setUserSlug] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [profileId, setProfileId] = useState(null);
  const [hasLebenslauf, setHasLebenslauf] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const navigate = useNavigate();

  const logout = useCallback(() => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setIsAuthenticated(false);
    setUser(null);
    setHasObrotny(false);
    setHasSzukajacy(false);
    setUserSlug(null);
    setAuthLoading(false);
    setLoading(false);
    setProfileId(null);
    setHasLebenslauf(false);
    setDownloadUrl(null);
    navigate('/login/');
  }, [navigate]);

const refreshToken = useCallback(async () => {
  const refresh = localStorage.getItem('refresh_token');

  if (refresh) {
    try {
      const response = await axiosInstance.post('/api/token/refresh/', { refresh });
      if (response.status === 200) {
        localStorage.setItem('access_token', response.data.access);
        return true; // Token odświeżony pomyślnie
      }
    } catch (error) {
      console.error('Token refresh error:', error);
    }
  }

  // Jeśli nie uda się odświeżyć tokena, wywołaj logout
  console.error('Token refresh failed');
  logout();
  return false; // Nie udało się odświeżyć tokena
}, [logout]);


  const fetchUserInfo = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get('/api/check-auth/');
      console.log('checkauth Full API response:', data);

      setUser(data.user_info);
      setHasObrotny(data.user_info.has_obrotny);
      setHasSzukajacy(data.user_info.has_szukajacy);
      setProfileId(data.user_info.profile_id);

      setHasLebenslauf(data.user_info.has_lebenslauf || false);
      setDownloadUrl(data.user_info.download_url || null);

      if (data.user_info.profile_slug) {
        setUserSlug(data.user_info.profile_slug);
        console.log('Profile slug set:', data.user_info.profile_slug);
      } else {
        setUserSlug(null);
      }
    } catch (error) {
      console.error('Fetch user info error:', error);
      // Jeśli otrzymamy 401 Unauthorized, wyloguj użytkownika
      if (error.response && error.response.status === 401) {
        logout();
      }
    } finally {
      setLoading(false);
      setAuthLoading(false);
    }
  }, [logout]);

  const checkAuthStatus = useCallback(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      setUser(null);
      setHasObrotny(false);
      setHasSzukajacy(false);
      setUserSlug(null);
      setProfileId(null);
      setHasLebenslauf(false);
      setDownloadUrl(null);
      setLoading(false);
      setAuthLoading(false);
    }
  }, []);

  useEffect(() => {
    setupInterceptors(refreshToken, logout);
    checkAuthStatus();
  }, [checkAuthStatus, refreshToken, logout]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserInfo();
    } else {
      setLoading(false);
      setAuthLoading(false);
    }
  }, [isAuthenticated, fetchUserInfo]);

  const login = async (username, password) => {
    try {
      const response = await axiosInstance.post('/api/token/', { username, password });
      if (response.status === 200) {
        const { access, refresh } = response.data;
        localStorage.setItem('access_token', access);
        localStorage.setItem('refresh_token', refresh);
        console.log('Tokens set during login:', { access, refresh });
        setIsAuthenticated(true);
        await fetchUserInfo();
        return true;
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
    setLoading(false);
    return false;
  };

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      user,
      hasObrotny,
      hasSzukajacy,
      loading,
      authLoading,
      login,
      logout,
      refreshToken,
      userSlug,
      profileId,
      hasLebenslauf,
      downloadUrl,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
